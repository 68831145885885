import axios from "axios";

import {
  competencyBaseUrl,
  programCompetenciesListUrl,
  programCompetenciesCreateUrl,
  programCompetenciesUpdateUrl,
  competencyLevelsListUrl,
  competencyLevelUrls,
  programReflectionPromptsUrls,
} from "../urls.js";

import { genericMixin } from "./generic.js";

const initialState = {
  programCompetencies: [],
  programCompetencyData: {},
  programExercises: [],
  competencyLevels: [],
  expandedCompetencies: [],
};

export const competencyModule = {
  namespaced: true,
  state: () => ({
    ...initialState,
  }),
  getters: {
    ...genericMixin.getters,
    competencies: (state) => {
      return state.programCompetencies || [];
    },
    structuredCompetencies: (state, getters) => {
      let competencies = getters.competencies;
      return competencies.map((competency) => {
        competency.dimensions = getters.competencyDimensions(competency.id);
        competency.levels = getters.levelsForCompetency(competency.id);
        return competency;
      });
    },
    baseCompetencies: (state, getters) => {
      return getters.structuredCompetencies
        .filter((competency) => !competency.competency_id)
        .sort((a, b) => (b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1));
    },
    publishedBaseCompetencyOptions: (state, getters) => {
      return getters.publishedCompetencies
        .map((competency) => ({
          label: competency.name,
          value: competency.id,
          dimensions: competency.dimensions.map((dimension) => dimension.id),
        }))
        .sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
        );
    },
    competencyCount: (state, getters) => {
      return getters.baseCompetencies.length;
    },
    publishedCompetencies: (state, getters) => {
      return getters.baseCompetencies.filter(
        (competency) => competency.published,
      );
    },
    unpublishedCompetencies: (state, getters) => {
      return getters.baseCompetencies.filter(
        (competency) => !competency.published,
      );
    },
    competencyDimensions: (state, getters) => (competencyId) => {
      return getters.competencies.filter(
        (dimension) => dimension.competency_id == competencyId,
      );
    },
    levelsForCompetency: (state) => (competencyId) => {
      let levels = state.competencyLevels || [];
      return levels.filter((level) => level.objective_id == competencyId);
    },
    getCompetencyLevelById: (state) => (levelId) => {
      return state.competencyLevels.find(
        (level) => level.id === Number(levelId),
      );
    },
    competencyId: () => {
      let competencyInfo = competencyBaseUrl.match(location.pathname);
      return competencyInfo ? competencyInfo.competencyId : null;
    },
    exercises: (state) => {
      return state.programExercises || [];
    },
    programDimensions: (state, getters) => {
      return getters.competencies.filter(
        (competency) => competency.competency_id != null,
      );
    },
    getCompetencyById: (state) => (competencyId) => {
      return state.programCompetencies.find(
        (competency) => competency.id === Number(competencyId),
      );
    },
    getDimensionById: (state, getters) => (dimensionId) => {
      return getters.programDimensions.find(
        (dimension) => dimension.id === dimensionId,
      );
    },
    competenciesForOpportunity: (state) => (opportunity) => {
      let competencyIds = opportunity.learning_objective_ids;
      return state.programCompetencies.filter((competency) => {
        return competencyIds.includes(competency.id);
      });
    },
    dimensionOptions(state, getters) {
      return getters.programDimensions.map((dimension) => ({
        label: dimension.name,
        value: dimension.id,
      }));
    },
  },
  mutations: {
    ...genericMixin.mutations,
    addCompetency(state, competencyData) {
      let index = state.programCompetencies.findIndex((competency) => {
        return (
          competency.name.toLowerCase() > competencyData.name.toLowerCase()
        );
      });
      if (index >= 0) {
        state.programCompetencies.splice(index, 0, competencyData);
      } else {
        state.programCompetencies.push(competencyData);
      }
    },
    setCompetencyData(state, { competencyData }) {
      state.programCompetencyData = competencyData;
    },
    setCompetencyList(state, { competencyList }) {
      state.programCompetencies = competencyList;
    },
    setProgramExercises(state, { exercises }) {
      state.programExercises = exercises;
    },
    setCompetencyLevels(state, { data }) {
      state.competencyLevels = data;
    },
    expandCompetency(state, competencyId) {
      state.expandedCompetencies.push(competencyId);
    },
    collapseCompetency(state, competencyId) {
      let index = state.expandedCompetencies.indexOf(competencyId);
      if (index >= 0) {
        state.expandedCompetencies.splice(index, 1);
      }
    },
  },
  actions: {
    // Generic Actions //
    ...genericMixin.actions,
    loadObjectList(
      { getters, commit },
      { mutation, apiUrl, urlParams = { programId: getters.programId } },
    ) {
      return axios.get(apiUrl.stringify(urlParams)).then((response) => {
        commit(mutation, { data: response.data });
      });
    },

    // Competency Actions //
    async loadCompetenciesList({ getters, commit }) {
      return axios
        .get(
          programCompetenciesListUrl.stringify({
            programId: getters.programId,
          }),
        )
        .then((response) => {
          commit("setCompetencyList", { competencyList: response.data });
        });
    },
    fetchNewCompetencyData({ dispatch }) {
      return dispatch("fetchNewInstanceData", {
        createUrl: programCompetenciesCreateUrl,
      });
    },
    fetchCompetencyData({ getters }, { competencyId }) {
      return axios.get(
        programCompetenciesUpdateUrl.stringify({
          programId: getters.programId,
          competencyId: competencyId,
        }),
      );
    },
    loadCompetencyData({ getters, commit, dispatch }) {
      let competencyId = getters.competencyId;
      return dispatch("fetchCompetencyData", { competencyId }).then(
        (response) => {
          commit("setCompetencyData", { competencyData: response.data });
        },
      );
    },
    saveCompetency({ dispatch }, competencyData) {
      return dispatch(
        competencyData.id ? "updateCompetency" : "createCompetency",
        competencyData,
      );
    },
    createCompetency({ getters }, competencyData) {
      let url = programCompetenciesCreateUrl.stringify({
        programId: getters.programId,
      });
      return axios.post(url, competencyData).then((response) => response.data);
    },
    updateCompetency({ getters }, competencyData) {
      let url = programCompetenciesUpdateUrl.stringify({
        programId: getters.programId,
        competencyId: getters.competencyId || competencyData.id,
      });
      return axios.put(url, competencyData).then((response) => response.data);
    },
    deleteCompetency({ getters }, competencyData) {
      let url = programCompetenciesUpdateUrl.stringify({
        programId: getters.programId,
        competencyId: getters.competencyId || competencyData.id,
      });
      return axios
        .delete(url, competencyData)
        .then((response) => response.data);
    },
    // Competency Dimensions //
    saveDimension({ getters, dispatch }, dimensionData) {
      if (!dimensionData.competency_id) {
        dimensionData.competency_id = getters.competencyId;
      }
      return dispatch(
        dimensionData.id ? "updateDimension" : "createDimension",
        dimensionData,
      );
    },
    createDimension({ getters }, dimensionData) {
      const url = programCompetenciesCreateUrl.stringify({
        programId: getters.programId,
      });
      return axios.post(url, dimensionData).then((response) => response.data);
    },
    updateDimension({ getters }, dimensionData) {
      const url = programCompetenciesUpdateUrl.stringify({
        programId: getters.programId,
        competencyId: dimensionData.id,
      });
      return axios.put(url, dimensionData).then((response) => response.data);
    },
    deleteDimension({ getters }, dimensionData) {
      const url = programCompetenciesUpdateUrl.stringify({
        programId: getters.programId,
        competencyId: dimensionData.id,
      });
      return axios.delete(url, dimensionData).then((response) => response.data);
    },
    expandCompetency({ commit }, competencyId) {
      commit("expandCompetency", competencyId);
    },
    collapseCompetency({ commit }, competencyId) {
      commit("collapseCompetency", competencyId);
    },
    // Competency Levels //
    async loadLevelList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setCompetencyLevels",
        apiUrl: competencyLevelsListUrl,
      });
    },
    fetchLevelData({ dispatch }, { competencyId, objectId = null }) {
      let urlParams = { competencyId: competencyId };
      return dispatch("fetchInstanceData", {
        urls: competencyLevelUrls,
        data: objectId ? { ...urlParams, id: objectId } : urlParams,
      }).then((response) => response);
    },
    saveLevel({ dispatch }, data) {
      data.competencyId = data.objective_id;
      return dispatch("saveInstanceData", { data, urls: competencyLevelUrls });
    },
    deleteLevel({ dispatch }, data) {
      data.competencyId = data.objective_id;
      return dispatch("deleteInstanceData", {
        data: data,
        urls: competencyLevelUrls,
      });
    },
    saveLevelReviewerPrompt({ dispatch }, { level, prompt }) {
      const data = { ...prompt, audience: "reviewer" };
      return dispatch("saveLevelReflectionPrompt", { level, prompt: data });
    },
    saveLevelUserPrompt({ dispatch }, { level, prompt }) {
      const data = { ...prompt, audience: "user" };
      return dispatch("saveLevelReflectionPrompt", { level, prompt: data });
    },
    saveLevelReflectionPrompt({ dispatch }, { level, prompt }) {
      const data = {
        ...prompt,
        context: "level",
        learning_objective_level_ids: [level.id],
      };
      return dispatch("saveInstanceData", {
        data,
        urls: programReflectionPromptsUrls,
      });
    },
    deleteLevelReflectionPrompt({ dispatch }, { prompt: data }) {
      return dispatch("deleteInstanceData", {
        data,
        urls: programReflectionPromptsUrls,
      });
    },
  },
};
