import { isEmpty } from "lodash";

import {
  adminProgramBaseUrl,
  programBadgesUrl,
  adminProgramBadgesUrl,
  // adminProgramCareersUrl,
  programAboutUrl,
  programBaseUrl,
  programHomepageUrl,
  programOpportunitiesUrl,
  programPlannerUrl,
  programCompetenciesUrl,
  participantFacingLevelRequestListUrl,
  programSurveyRouterUrl,
  programOnboardingUrl,
  programWelcomeUrl,
  programExerciseRouterUrl,
  programPreferenceSelectionUrl,
  adminProgramOverviewUrl,
  adminProgramDashboardUrl,
  adminProgramConfigurationUrl,
  adminProgramOpportunitiesUrl,
  adminProgramCompetenciesUrl,
  adminProgramExercisesUrl,
  adminProgramMembersUrl,
  adminProgramMembersActivityUrl,
  adminLevelRequestsUrl,
  adminProgramOnboardingSlidesUrl,
  adminProgramAnalyticsUrl,
} from "../urls.js";

import { getNavigationCounts } from "@/programs/services/NavigationService.js";

// For super admin pages, programId is a string (e.g., 'overview' for the page '/admin/programs/overview')
// So we need to check whether it's a number or not
const adminInterface = adminProgramBaseUrl.match(location.pathname);
const programInfo =
  adminInterface && isNaN(adminInterface.programId)
    ? null
    : adminInterface || programBaseUrl.match(location.pathname);
export const navigationModule = {
  namespaced: true,
  state: () => ({
    programId: programInfo ? programInfo.programId : null,
    adminInterface: !!adminInterface,
    participantInterface: !adminInterface,
    navigationCounts: {},
    programNavItems: [
      {
        pattern: programHomepageUrl,
        text: "Program Dashboard",
        key: "homepage",
        visible: true,
      },
      {
        pattern: programCompetenciesUrl,
        text: "Competencies",
        key: "competencies",
        visible: true,
      },
      {
        pattern: programOpportunitiesUrl,
        text: "Opportunities",
        key: "opportunities",
        visible: true,
      },
      {
        pattern: participantFacingLevelRequestListUrl,
        text: "Level-Up Requests",
        key: "levelup_requests",
        visible: true,
      },
      {
        pattern: programBadgesUrl,
        text: "Badges",
        key: "badges",
        visible: false,
      },
      { pattern: programAboutUrl, text: "About", key: "about", visible: true },
      //{pattern: programStatusUrl, text: 'Progress', key: 'status'},
    ],
    programAdminNavItems: [
      {
        pattern: adminProgramDashboardUrl,
        text: "Dashboard",
        key: "admin_dashboard",
      },
      {
        pattern: adminProgramConfigurationUrl,
        text: "General Settings",
        key: "admin_config",
      },
      { pattern: adminProgramBadgesUrl, text: "Badges", key: "admin_badges" },
      // {
      //   pattern: adminProgramCareersUrl,
      //   text: "Careers",
      //   key: "admin_careers",
      // },
      {
        pattern: adminProgramCompetenciesUrl,
        text: "Competencies",
        key: "admin_competencies",
      },
      {
        pattern: adminProgramOpportunitiesUrl,
        text: "Opportunities",
        key: "admin_opportunities",
        hasNotifications: true,
      },
      {
        pattern: adminLevelRequestsUrl,
        text: "Level-up Requests",
        key: "admin_levelup_requests",
        hasNotifications: true,
      },
      {
        pattern: adminProgramOnboardingSlidesUrl,
        text: "Onboarding Slides",
        key: "admin_onboarding_slides",
      },
      {
        pattern: adminProgramExercisesUrl,
        text: "Exercises",
        key: "admin_exercises",
      },
      {
        pattern: adminProgramMembersUrl,
        text: "Members",
        key: "admin_members",
      },
      {
        pattern: adminProgramMembersActivityUrl,
        text: "User Activity",
        key: "admin_user_activity",
      },
      {
        pattern: adminProgramAnalyticsUrl,
        text: "Analytics",
        key: "admin_analytics",
      },
    ],
    programAssistantNavItems: [
      {
        pattern: adminProgramDashboardUrl,
        text: "Dashboard",
        key: "admin_dashboard",
      },
      { pattern: adminProgramBadgesUrl, text: "Badges", key: "admin_badges" },
      // {
      //   pattern: adminProgramCareersUrl,
      //   text: "Careers",
      //   key: "admin_careers",
      // },
      {
        pattern: adminProgramCompetenciesUrl,
        text: "Competencies",
        key: "admin_competencies",
      },
      {
        pattern: adminProgramOpportunitiesUrl,
        text: "Opportunities",
        key: "admin_opportunities",
      },
      {
        pattern: adminLevelRequestsUrl,
        text: "Level-Up Requests",
        key: "admin_levelup_requests",
        hasNotifications: true,
      },
      {
        pattern: adminProgramMembersUrl,
        text: "Members",
        key: "admin_members",
      },
      {
        pattern: adminProgramMembersActivityUrl,
        text: "Cohorts",
        key: "admin_user_activity",
      },
    ],
    programOnboardingPages: [
      { pattern: programOnboardingUrl, text: "Onboarding", key: "onboarding" },
      { pattern: programWelcomeUrl, text: "Welcome", key: "welcome" },
      { pattern: programSurveyRouterUrl, text: "Survey", key: "survey" },
      { pattern: programExerciseRouterUrl, text: "Exercise", key: "exercise" },
      {
        pattern: programPreferenceSelectionUrl,
        text: "Prioritization",
        key: "prioritization",
      },
    ],
    programAdminOverviewUrl: adminProgramOverviewUrl.stringify(),
  }),
  getters: {
    navigationCountsLoaded: (state) => {
      return !isEmpty(state.navigationCounts);
    },
    getNotifications:
      (state) =>
      ({ key }) => {
        if (key === "admin_levelup_requests") {
          return state.navigationCounts.not_started_level_up_request_count;
        } else if (key === "levelup_requests") {
          return state.navigationCounts
            .changes_requested_level_up_request_count;
        } else if (key === "admin_opportunities") {
          return state.navigationCounts.needs_review_opportunity_count;
        } else {
          return 0;
        }
      },
    participantInterfaceNavItems: (state, getters, rootState, rootGetters) => {
      const baseNavList = state.programNavItems;
      const hasBadges = rootGetters["programConfig"].has_badges;
      if (hasBadges) {
        const badgeNavItem = baseNavList.find((item) => item.key === "badges");
        badgeNavItem.visible = true;
      }
      const pageInfo = { programId: state.programId };
      return state.programId
        ? baseNavList.reduce((navList, navitem) => {
            if (navitem.visible) {
              navitem.url = navitem.pattern.stringify(pageInfo);
              navitem.active = navitem.url === location.pathname;
              navitem.notifications = getters.getNotifications(navitem);
              navList.push(navitem);
            }
            return navList;
          }, [])
        : [];
    },
    adminInterfaceNavItems: (state, getters, rootState, rootGetters) => {
      const navlist = rootGetters["user/isProgramAssistant"]
        ? state.programAssistantNavItems
        : state.programAdminNavItems;
      return state.programId
        ? navlist.map(function (navitem) {
            navitem.url = navitem.pattern.stringify({
              programId: state.programId,
            });
            navitem.active = navitem.url === location.pathname;
            navitem.notifications = getters.getNotifications(navitem);
            return navitem;
          })
        : [];
    },
    onboardingPages: (state) => {
      const pages = state.programOnboardingPages;
      if (state.programId) {
        const pageInfo = { programId: state.programId };
        return pages.map(function (page) {
          page.url = page.pattern.stringify(pageInfo);
          page.active = page.url === location.pathname;
          return page;
        });
      } else {
        return null;
      }
    },
    navItems: (state, getters) => {
      return state.programId && getters.navigationCountsLoaded
        ? state.adminInterface
          ? getters.adminInterfaceNavItems
          : getters.participantInterfaceNavItems
        : [];
    },
    programAdminDashboard: (state, getters) => {
      return getters.adminInterfaceNavItems.find(
        (navitem) => navitem.key == "admin_dashboard",
      );
    },
    programAdminOpportunitiesPage: (state, getters) => {
      return getters.adminInterfaceNavItems.find(
        (navitem) => navitem.key == "admin_opportunities",
      );
    },
    programHomepage: (state, getters) => {
      return getters.participantInterfaceNavItems.find(
        (navitem) => navitem.key == "homepage",
      );
    },
    programOpportunitiesPage: (state, getters) => {
      return getters.participantInterfaceNavItems.find(
        (navitem) => navitem.key == "opportunities",
      );
    },
    programOpportunityPlannerPage: (state) => {
      return {
        url: programPlannerUrl.stringify({ programId: state.programId }),
      };
    },
    programCompetenciesPage: (state, getters) => {
      return getters.participantInterfaceNavItems.find(
        (navitem) => navitem.key == "competencies",
      );
    },
    programLevelUpRequestOverviewPage: (state) => {
      return {
        url: participantFacingLevelRequestListUrl.stringify({
          programId: state.programId,
        }),
      };
    },
    competencySelectionPage: (state, getters) => {
      return getters.onboardingPages.find(
        (navitem) => navitem.key == "prioritization",
      );
    },
  },
  mutations: {
    setNavigationCounts(state, counts) {
      state.navigationCounts = counts;
    },
  },
  actions: {
    async loadNavigationCounts({ commit, state }) {
      const counts = await getNavigationCounts(
        state.programId,
        state.adminInterface,
      );
      commit("setNavigationCounts", counts);
    },
  },
};
