import "core-js/stable/index.js";
import "regenerator-runtime/runtime.js";

import { createApp, defineAsyncComponent } from "vue";
import { createPinia } from "pinia";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@/font-awesome.js";
import { formatDatesMixin } from "@/programs/mixins/formatDates.js";
import SpireComponents from "../plugins/core/plugin.ts";
import SpireRouter from "../plugins/routes/plugin";
import RollbarPlugin from "../plugins/rollbar/plugin";
import { routes } from "@/programs/routes.js";
import { store } from "../vue/programs/stores/base.js";
import FocusTrapDirective from "@/programs/directives/FocusTrapDirective.js";
import { clickOutside } from "../vue/courses/utilities/ClickOutside.js";

// outside components
import "flatpickr/dist/flatpickr.css";
import flatPickr from "vue-flatpickr-component";
import VueSkipTo from "@vue-a11y/skip-to";

// formkit configs
import { plugin, defaultConfig } from "@formkit/vue";
import formkitConfig from "@/programs/formkit.config.js";

// prettier-ignore
const componentImports = {
  ProgramHomepage: () => import("@/programs/views/student_homepage/ProgramHomepage.vue"),
  ProgramAboutPage: () => import("@/programs/views/student_about/ProgramAboutPage.vue"),
  ProgramWelcome: () => import("@/programs/views/student_welcome/ProgramWelcome.vue"),
  ProgramSurvey: () => import("@/programs/views/student_survey/ProgramSurvey.vue"),
  PreferenceExercise: () => import("@/programs/views/student_preference_exercise/PreferenceExercise.vue"),
  PreferenceSelection: () => import("@/programs/views/student_preference_selection/PreferenceSelection.vue"),
  CompetencyLevelset: () => import("@/programs/views/student_competency_levelset/CompetencyLevelset.vue"),
  CompetencyOverview: () => import("@/programs/views/student_competencies/CompetencyOverview.vue"),
  CompetencyDetail: () => import("@/programs/views/student_competency/StudentCompetencyDetail.vue"),
  LevelUpRequests: () => import("@/programs/views/student_levelup_requests/LevelUpRequests.vue"),
  InitialAssessmentEdit: () => import("@/programs/views/student_levelup_requests/InitialAssessmentEdit.vue"),
  InitialAssessmentReview: () => import("@/programs/views/student_levelup_requests/InitialAssessmentReview.vue"),
  LevelUpRequestDetail: () => import("@/programs/views/student_levelup_requests/LevelUpRequestDetail.vue"),
  LevelUpRequestEdit: () => import("@/programs/views/student_levelup_request_edit/LevelUpRequestEdit.vue"),
  LevelSelection: () => import("@/programs/views/student_levelup_requests/LevelSelection.vue"),
  OpportunityDetail: () => import("@/programs/views/student_opportunities/OpportunityDetail.vue"),
  OpportunityExplorer: () => import("@/programs/views/student_opportunities/OpportunityExplorer.vue"),
  OpportunityPlanner: () => import("@/programs/views/student_opportunities/OpportunityPlanner.vue"),
  OpportunityReflection: () => import("@/programs/views/student_opportunity_reflection/OpportunityReflection.vue"),
  StudentCareerIndex: () => import("@/programs/views/student_careers/StudentCareerIndex.vue"),
  StudentCareerDetail: () => import("../vue/programs/views/student_careers/StudentCareerDetail.vue"),
  StudentCompetencyCategoryDetail: () => import("@/programs/views/student_competency_category/StudentCompetencyCategoryDetail.vue"),
  StudentCreatedOpportunity: () => import("@/programs/views/student_created_opportunity/StudentCreatedOpportunity.vue"),
  StudentBadgeDetail: () => import("@/programs/views/student_badges/StudentBadgeDetail.vue"),
  StudentBadgeIndex: () => import("@/programs/views/student_badges/StudentBadgeIndex.vue"),
  StudentOnboarding: () => import("@/programs/views/student_onboarding/StudentOnboarding.vue"),
  ProgramUserStatus: () => import("@/programs/views/student_status/ProgramUserStatus.vue"),

  AdminProgramsOverview: () => import("@/programs/views/admin_programs/AdminProgramsOverview.vue"),
  AdminProgramDashboard: () => import("@/programs/views/admin_dashboard/AdminProgramDashboard.vue"),
  AdminBadgeIndex: () => import("@/programs/views/admin_badges/AdminBadgeIndex.vue"),
  AdminBadgeDetail: () => import("@/programs/views/admin_badges/AdminBadgeDetail.vue"),
  AdminBadgeEdit: () => import("@/programs/views/admin_badges/AdminBadgeEdit.vue"),
  AdminBadgeQuickAward: () => import("@/programs/views/admin_badges/AdminBadgeQuickAward.vue"),
  AdminCareerIndex: () => import("@/programs/views/admin_careers/AdminCareerIndex.vue"),
  AdminCareerDetail: () => import("@/programs/views/admin_careers/AdminCareerDetail.vue"),
  AdminCareerEdit: () => import("@/programs/views/admin_careers/AdminCareerEdit.vue"),
  AdminCohortCreate: () => import("@/programs/views/admin_cohorts/AdminCohortCreate.vue"),
  AdminCohortDetail: () => import("@/programs/views/admin_cohorts/AdminCohortDetail.vue"),
  AdminCohortEdit: () => import("@/programs/views/admin_cohorts/AdminCohortEdit.vue"),
  AdminCohortsOverview: () => import("@/programs/views/admin_cohorts/AdminCohortsOverview.vue"),
  AdminConfiguration: () => import("@/programs/views/admin_configuration/AdminConfiguration.vue"),
  AdminCompetencies: () => import("@/programs/views/admin_competencies/AdminCompetencies.vue"),
  AdminCompetencyDetail: () => import("@/programs/views/admin_competency_detail/AdminCompetencyDetail.vue"),
  AdminCompetencyEdit: () => import("@/programs/views/admin_competency_edit/AdminCompetencyEdit.vue"),
  AdminDimensionDetail: () => import("@/programs/views/admin_dimension_detail/AdminDimensionDetail.vue"),
  AdminDimensionEdit: () => import("@/programs/views/admin_dimension_edit/AdminDimensionEdit.vue"),
  AdminLevelDetail: () => import("@/programs/views/admin_level_detail/AdminLevelDetail.vue"),
  AdminLevelEdit: () => import("@/programs/views/admin_level_edit/AdminLevelEdit.vue"),
  AdminOnboardingSlides: () => import("@/programs/views/admin_onboarding_slides/AdminOnboardingSlides.vue"),
  AdminOnboardingSlideDetail: () => import("@/programs/views/admin_onboarding_slide_detail/AdminOnboardingSlideDetail.vue"),
  AdminOnboardingSlideEdit: () => import("@/programs/views/admin_onboarding_slide_edit/AdminOnboardingSlideEdit.vue"),
  AdminOpportunities: () => import("@/programs/views/admin_opportunities/AdminOpportunities.vue"),
  AdminOpportunityEdit: () => import("@/programs/views/admin_opportunities/AdminOpportunityEdit.vue"),
  AdminOpportunityDetail: () => import("@/programs/views/admin_opportunities/AdminOpportunityDetail.vue"),
  AdminMembersOverview: () => import("@/programs/views/admin_members/AdminMembersOverview.vue"),
  AdminTimeframesOverview: () => import("@/programs/views/admin_timeframes/AdminTimeframesOverview.vue"),
  AdminUserActivity: () => import("@/programs/views/admin_user_activity/AdminUserActivity.vue"),
  AdminMemberDetail: () => import("@/programs/views/admin_member_detail/AdminMemberDetail.vue"),
  AdminExerciseIndex: () => import("@/programs/views/admin_exercises/AdminExerciseIndex.vue"),
  AdminExerciseDetail: () => import("@/programs/views/admin_exercise_detail/AdminExerciseDetail.vue"),
  AdminExerciseEdit: () => import("@/programs/views/admin_exercise_edit/AdminExerciseEdit.vue"),
  AdminLevelRequests: () => import("@/programs/views/admin_level_requests/AdminLevelRequests.vue"),
  AdminLevelRequestDetail: () => import("@/programs/views/admin_level_requests/AdminLevelRequestDetail.vue"),
  AdminLevelRequestReview: () => import("@/programs/views/admin_level_requests/AdminLevelRequestReview.vue"),
  AdminAnnouncementDetail: () => import("@/programs/views/admin_announcements/AdminAnnouncementDetail.vue"),
  AdminAchievementIssuerDetail: () => import("@/programs/views/admin_achievement_issuers/AdminAchievementIssuerDetail.vue"),
  PublicAchievementDetail: () => import("@/programs/views/public_achievement_detail/PublicAchievementDetail.vue"),
  AdminAnalyticsIndex: () => import("@/programs/views/admin_analytics/AdminAnalyticsIndex.vue"),
};

const vueApp = document.querySelector("#vue-template");
if (vueApp) {
  const pinia = createPinia();
  const app = createApp({});
  app.use(pinia);
  app.component("FlatPickr", flatPickr);
  app.component("FaIcon", FontAwesomeIcon);
  Object.entries(componentImports).forEach(
    ([componentName, componentImport]) => {
      const asyncComponent = defineAsyncComponent(componentImport);
      app.component(componentName, asyncComponent);
    },
  );
  app.directive("click-outside", clickOutside);
  app.directive("focus-trap", FocusTrapDirective);
  app
    .use(VueSkipTo)
    .use(SpireComponents)
    .use(SpireRouter, { routes })
    .use(store)
    .use(plugin, defaultConfig(formkitConfig))
    .use(RollbarPlugin, {
      token: import.meta.env.VITE_ROLLBAR_CLIENT_TOKEN,
      environment: import.meta.env.VITE_ROLLBAR_ENV,
    });
  app.mixin(formatDatesMixin).mixin({
    methods: {
      notEmpty: (obj) => Object.keys(obj).length > 0,
      validReferrerOr(fallbackUrl) {
        const isReferrerFromSameDomain =
          document.referrer.indexOf(window.location.host) !== -1;
        return isReferrerFromSameDomain ? document.referrer : fallbackUrl;
      },
    },
  });

  app.mount(vueApp);
}
