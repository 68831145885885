import {
  opportunityAdvancedOptions,
  opportunityAdminStatuses,
  opportunitySchedulingOptions,
  opportunitySources,
} from "../utils/constants.js";

import {
  AUTHOR_TYPE_ASCENDING,
  AUTHOR_TYPE_DESCENDING,
  CREATOR_NAME_ASCENDING,
  CREATOR_NAME_DESCENDING,
  DATE_ASCENDING,
  DATE_DESCENDING,
  NAME_ASCENDING,
  STATUS_ASCENDING,
  STATUS_DESCENDING,
  compareChain,
  compareStrings,
  compareISODateStrings,
} from "../utils/sort.js";

const tieBreaker = compareStrings({
  value: (opportunity) => opportunity.name,
  asc: true,
});

const compareOpportunities = (sortMethodName) => {
  if (sortMethodName === DATE_ASCENDING || sortMethodName === DATE_DESCENDING) {
    const dateAscending = sortMethodName === DATE_ASCENDING;
    return compareChain(
      compareISODateStrings({
        value: (opportunity) => opportunity.activity_date_range.start,
        asc: dateAscending,
      }),
      tieBreaker,
    );
  } else if (
    sortMethodName === AUTHOR_TYPE_ASCENDING ||
    sortMethodName === AUTHOR_TYPE_DESCENDING
  ) {
    const authorTypeAscending = sortMethodName === AUTHOR_TYPE_ASCENDING;
    return compareChain(
      compareStrings({
        value: (opportunity) =>
          opportunity.creator ? opportunity.creator.full_name : "",
        asc: authorTypeAscending,
      }),
      tieBreaker,
    );
  } else if (
    sortMethodName === CREATOR_NAME_ASCENDING ||
    sortMethodName === CREATOR_NAME_DESCENDING
  ) {
    const creatorNameAscending = sortMethodName === CREATOR_NAME_ASCENDING;
    return compareChain(
      compareStrings({
        value: (opportunity) =>
          opportunity.creator ? opportunity.creator.full_name : "",
        asc: creatorNameAscending,
      }),
      tieBreaker,
    );
  } else if (
    sortMethodName === STATUS_ASCENDING ||
    sortMethodName === STATUS_DESCENDING
  ) {
    const statusAscending = sortMethodName === STATUS_ASCENDING;
    return compareChain(
      compareStrings({
        value: (opportunity) => opportunity.status.toLowerCase(),
        asc: statusAscending,
      }),
      tieBreaker,
    );
  } else {
    const nameAscending = sortMethodName === NAME_ASCENDING;
    return compareStrings({
      value: (opportunity) => opportunity.name,
      asc: nameAscending,
    });
  }
};

function filterBySearchText(object) {
  let opportunity = object.assignment || object;
  let name = opportunity.name.toLowerCase();
  let searchText = opportunityAdminToolbarModule.state.searchText.toLowerCase();
  return name.includes(searchText);
}

export const opportunityAdminToolbarModule = {
  namespaced: true,
  state: {
    searchText: "",
    sort: NAME_ASCENDING,
    selectedAdvancedFilters: [],
    selectedCompetencyFilters: [],
    selectedSchedulingFilters: [],
    selectedSourceFilters: [],
    selectedStatusFilters: [],
  },
  getters: {
    showArchivedOpportunities(state, getters) {
      return getters.selectedAdvancedFilterValues.includes(
        opportunityAdvancedOptions.showArchived.value,
      );
    },
    showParticipantCreatedOpportunities(state, getters) {
      return getters.selectedAdvancedFilterValues.includes(
        opportunityAdvancedOptions.showParticipantCreated.value,
      );
    },
    selectedCompetencyFilterIds(state) {
      return state.selectedCompetencyFilters.map((f) => f.value);
    },
    selectedAdvancedFilterValues(state) {
      return state.selectedAdvancedFilters.map((f) => f.value);
    },
    selectedSchedulingFilterValues(state) {
      return state.selectedSchedulingFilters.map((f) => f.value);
    },
    selectedSourceFilterValues(state) {
      return state.selectedSourceFilters.map((f) => f.value);
    },
    selectedStatusFilterValues(state) {
      return state.selectedStatusFilters.map((f) => f.value);
    },
    filteredOpportunities: (state, getters) => (opportunities) => {
      opportunities = getters.showArchivedOpportunities
        ? opportunities
        : opportunities.filter(
            (opportunity) =>
              opportunity.status !== opportunityAdminStatuses.archived,
          );
      opportunities = getters.showParticipantCreatedOpportunities
        ? opportunities
        : opportunities.filter(
            (opportunity) =>
              opportunity.source !== opportunitySources.participant.value,
          );
      if (state.searchText.length) {
        opportunities = opportunities.filter(filterBySearchText);
      }
      if (state.selectedCompetencyFilters.length) {
        let selectedCompetencyIds = getters.selectedCompetencyFilterIds;
        opportunities = opportunities.filter((opportunity) => {
          return opportunity.learning_objective_ids.some((competencyId) => {
            return selectedCompetencyIds.includes(competencyId);
          });
        });
      }
      if (state.selectedSchedulingFilters.length) {
        let selectedSchedulingFilterValues =
          getters.selectedSchedulingFilterValues;
        opportunities = opportunities.filter((opportunity) => {
          let opportunitySchedulingType = opportunity.activity_dates.length
            ? opportunitySchedulingOptions.predefined.value
            : opportunitySchedulingOptions.selfScheduled.value;
          return selectedSchedulingFilterValues.includes(
            opportunitySchedulingType,
          );
        });
      }
      if (state.selectedSourceFilters.length) {
        let selectedSourceValues = getters.selectedSourceFilterValues;
        opportunities = opportunities.filter((opportunity) => {
          return selectedSourceValues.includes(opportunity.source);
        });
      }
      if (state.selectedStatusFilters.length) {
        let selectedStatusValues = getters.selectedStatusFilterValues;
        opportunities = opportunities.filter((opportunity) => {
          return selectedStatusValues.includes(opportunity.status);
        });
      }
      return opportunities;
    },
    filteredAndSortedOpportunities: (state, getters) => (opportunities) => {
      const compareFn = compareOpportunities(state.sort);
      return getters.filteredOpportunities([...opportunities]).sort(compareFn);
    },
    someFiltersActive(state) {
      return (
        state.searchText.length ||
        state.selectedAdvancedFilters.length ||
        state.selectedCompetencyFilters.length ||
        state.selectedSchedulingFilters.length ||
        state.selectedSourceFilters.length ||
        state.selectedStatusFilters.length
      );
    },
  },
  mutations: {
    updateSearchText(state, searchText) {
      state.searchText = searchText;
    },
    updateFilter(state, { filterListName, filters }) {
      state[filterListName] = filters;
    },
    removeFilter(state, { filterListName, filter }) {
      let filterIndex = state[filterListName].findIndex(
        (f) => f.value === filter.value,
      );
      if (filterIndex > -1) {
        state[filterListName].splice(filterIndex, 1);
      }
    },
    updateSort(state, sort) {
      state.sort = sort;
    },
  },
};
