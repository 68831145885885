import { apiClient } from "@/programs/services/ApiClient.js";
import { programLevelRequestUrls } from "@/programs/urls.js";

export const releaseLevelUpRequests = (programId, requests) => {
  const url = programLevelRequestUrls.release.stringify({ programId });
  return apiClient
    .post(url, requests)
    .then(({ data, status }) => {
      return { data, status };
    })
    .catch((error) => {
      if (error.response) {
        const { data, status } = error.response;
        const errors = data;
        return { errors, status };
      } else {
        console.error("Error", error);
      }
    });
};
