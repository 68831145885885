<template>
  <div
    class="file-upload--filename-wrapper mb-0_5"
    style="display: flex; flex-direction: column"
  >
    <div
      v-if="previewURL"
      class="file-upload__preview-wrapper dropzone--drop p-3 mb-1"
      @drop.prevent="handleDrop"
      @dragenter.prevent
      @dragover.prevent
    >
      <img :src="previewURL" class="file-upload__image-preview" />
      <spire-button
        variant="warning"
        icon-left="trash"
        @click="handleClearImage"
      />
    </div>
    <div
      v-else
      class="dropzone p-3"
      @drop.prevent="handleDrop"
      @dragenter.prevent
      @dragover.prevent
    >
      Drop files to upload, or
      <spire-button
        title="Browse"
        variant="secondary"
        @click="handleChooseImage"
        icon-left="upload"
      />
    </div>
    <form-kit v-if="purge" type="hidden" :name="purgeInputName" :value="1" />
  </div>
  <br />
  <input ref="fileInput" type="file" @input="handleInput" hidden />
</template>
<script setup>
import { computed, ref } from "vue";
import { isEmpty, camelCase } from "lodash";

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const purge = ref(false);
const fileInput = ref();

const previewURL = computed(() => {
  const value = props.context._value;
  if (value instanceof File) {
    return URL.createObjectURL(value);
  } else {
    return purge.value ? undefined : props.context.original?.src;
  }
});

const purgeInputName = computed(() =>
  camelCase(`purge_${props.context.node.name}`),
);

const setFile = (files) => {
  if (!isEmpty(files)) {
    purge.value = false;
    props.context.node.input(files[0]);
  }
};

const handleDrop = (e) => setFile(e.dataTransfer.files);
const handleInput = (event) => setFile(event.target.files);
const handleChooseImage = () => fileInput.value.click();

const handleClearImage = () => {
  props.context.node.input(undefined);
  if (props.context.original) {
    purge.value = true;
  }
};
</script>
