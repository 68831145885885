import axios from "axios";

import {
  opportunityBaseUrl,
  programOpportunityCategoriesUrls,
  programOpportunityUrls,
  programOpportunitiesCreateUrl,
  programOpportunityDateUrls,
  programOpportunityDetailUrl,
  programOpportunityReflectionUrl,
  programReflectionPromptsUrls,
  programEffortUrls,
  googleCalendarPlannerUrl,
  googleCalendarOpportunityUrl,
} from "../urls.js";

import { genericMixin } from "./generic.js";
import {
  opportunityAdminStatuses,
  opportunitySources,
  RESPONSE_STATUSES,
} from "../utils/constants.js";

const initialState = {
  opportunityCategories: [],
  opportunities: [],
  opportunityDates: [],
  effortLevels: [],
  reflectionPrompts: [],
};

export const opportunityModule = {
  namespaced: true,
  state: () => ({
    ...initialState,
  }),
  getters: {
    ...genericMixin.getters,
    getOpportunityById: (state) => (opportunityId) => {
      return (
        state.opportunities.find(
          (opportunity) => opportunity.id === Number(opportunityId),
        ) || null
      );
    },
    opportunityId: () => {
      let pageInfo = opportunityBaseUrl.match(location.pathname);
      return pageInfo ? opportunityBaseUrl.opportunityId : null;
    },
    datesForOpportunity: () => (opportunityId) => {
      return this.opportunityDates.filter(
        (opportunityDate) => opportunityDate.assignment.id === opportunityId,
      );
    },
    opportunityUrl: (state, getters) => (opportunity) => {
      return programOpportunityDetailUrl.stringify({
        programId: getters.programId,
        opportunityId: opportunity.id,
      });
    },
    reflectionUrl: (state, getters) => (activity) => {
      return programOpportunityReflectionUrl.stringify({
        programId: getters.programId,
        reflectionId: activity.submission_id,
      });
    },
    publishedOpportunities: (state) => {
      return state.opportunities.filter(
        (opportunity) =>
          opportunity.status === opportunityAdminStatuses.published,
      );
    },
    draftOpportunities: (state) => {
      return state.opportunities.filter(
        (opportunity) => opportunity.status === opportunityAdminStatuses.draft,
      );
    },
    needsReviewOpportunities: (state) => {
      return state.opportunities.filter(
        (opportunity) =>
          opportunity.status === opportunityAdminStatuses.needsReview,
      );
    },
    archivedOpportunities: (state) => {
      return state.opportunities.filter(
        (opportunity) =>
          opportunity.status === opportunityAdminStatuses.archived,
      );
    },
    publicPublishedOpportunities: (state, getters) => {
      return getters.publishedOpportunities.filter(
        (opportunity) =>
          opportunity.source !== opportunitySources.participant.value,
      );
    },
    participantCreatedOpportunities: (state) => {
      return state.opportunities.filter(
        (opportunity) =>
          opportunity.source === opportunitySources.participant.value,
      );
    },
    selfScheduledOpportunities: (state) => {
      return state.opportunities.filter(
        (opportunity) => !opportunity.activity_dates.length,
      );
    },
    upcomingOpportunities: (state, getters) => {
      return state.opportunities
        .filter((opportunity) =>
          opportunity.activity_dates.some((opportunityDate) => {
            let today = new Date();
            return new Date(opportunityDate.end_date) >= today;
          }),
        )
        .concat(getters.selfScheduledOpportunities);
    },
    pastOpportunities: (state) => {
      return state.opportunities.filter(
        (opportunity) =>
          opportunity.activity_dates.length &&
          opportunity.activity_dates.every((opportunityDate) => {
            let today = new Date();
            return new Date(opportunityDate.end_date) < today;
          }),
      );
    },
    upcomingPublishedOpportunities: (state, getters) => {
      return getters.upcomingOpportunities.filter(
        (opportunity) =>
          opportunity.status === opportunityAdminStatuses.published,
      );
    },
    pastPublishedOpportunities: (state, getters) => {
      return getters.pastOpportunities.filter(
        (opportunity) =>
          opportunity.status === opportunityAdminStatuses.published,
      );
    },
    opportunityReflectionPrompts: (state) => {
      return state.reflectionPrompts.filter(
        (reflectionPrompt) =>
          reflectionPrompt.context === "opportunity" &&
          reflectionPrompt.audience === "user",
      );
    },
    opportunityReflectionPromptsForCategory:
      (state, getters) => (categoryId) => {
        return getters.opportunityReflectionPrompts.filter((reflectionPrompt) =>
          reflectionPrompt.assignment_type_ids.includes(categoryId),
        );
      },
    participantFacinglevelReflectionPrompts: (state) => {
      return state.reflectionPrompts.filter(
        (reflectionPrompt) =>
          reflectionPrompt.audience === "user" &&
          reflectionPrompt.context === "level",
      );
    },
    orderedOpportunityCategories: (state) => {
      return state.opportunityCategories.sort((a, b) => {
        return b.position < a.position ? 1 : -1;
      });
    },
    opportunityCategoryOptions: (state, getters) => {
      return getters.orderedOpportunityCategories.map((category) => ({
        label: category.name,
        value: category.id,
      }));
    },
    opportunityIntensityOptions: (state) => {
      return state.effortLevels.map((effortLevel) => ({
        label: effortLevel.name,
        value: effortLevel.id,
      }));
    },
  },
  mutations: {
    setOpportunityCategories(state, { data }) {
      state.opportunityCategories = data;
    },
    setOpportunityDates(state, { data }) {
      state.opportunityDates = data;
    },
    setOpportunityData(state, { opportunityData }) {
      state.opportunityData = opportunityData;
    },
    setOpportunityList(state, { data }) {
      state.opportunities = data;
    },
    setEffortLevels(state, { data }) {
      state.effortLevels = data;
    },
    setReflectionPrompts(state, { data }) {
      state.reflectionPrompts = data;
    },
  },
  actions: {
    ...genericMixin.actions,
    // Opportunity Category Actions //
    loadOpportunityCategoryList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setOpportunityCategories",
        urls: programOpportunityCategoriesUrls,
      });
    },
    // End Opportunity Category Actions //
    // Opportunity Actions //
    async loadOpportunitiesList({ dispatch }) {
      await dispatch("loadObjectList", {
        mutation: "setOpportunityList",
        urls: programOpportunityUrls,
      });
    },
    fetchNewOpportunityData({ dispatch }) {
      return dispatch("fetchNewInstanceData", {
        createUrl: programOpportunitiesCreateUrl,
      });
    },
    fetchOpportunityData({ dispatch }, data) {
      return dispatch("fetchInstanceDetailsData", {
        data: data,
        urls: programOpportunityUrls,
      });
    },
    saveOpportunity({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: programOpportunityUrls,
      });
    },
    deleteOpportunity({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: programOpportunityUrls,
      });
    },
    addOpportunityToGoogleCalendar(context, data) {
      let url = googleCalendarOpportunityUrl.stringify({
        programId: data.programId,
        objectId: data.opportunityId,
      });
      return axios
        .post(url, data)
        .then((response) => {
          if (response.data.authorization_url) {
            if (sessionStorage.getItem("resendOpportunityCalendarEvent")) {
              sessionStorage.removeItem("resendOpportunityCalendarEvent");
            } else {
              sessionStorage.setItem("resendOpportunityCalendarEvent", true);
              window.location.href = response.data.authorization_url;
            }
          } else {
            sessionStorage.removeItem("resendOpportunityCalendarEvent");
            return response.data;
          }
        })
        .catch((error) => {
          return {
            status: RESPONSE_STATUSES.failure,
            message: error.response.statusText,
          };
        });
    },
    addPlannerToGoogleCalendar(context, data) {
      let url = googleCalendarPlannerUrl.stringify({
        programId: data.programId,
      });
      return axios
        .post(url, data)
        .then((response) => {
          if (response.data.authorization_url) {
            if (sessionStorage.getItem("resendPlannerCalendarEvents")) {
              sessionStorage.removeItem("resendPlannerCalendarEvents");
            } else {
              sessionStorage.setItem("resendPlannerCalendarEvents", true);
              window.location.href = response.data.authorization_url;
            }
          } else {
            sessionStorage.removeItem("resendPlannerCalendarEvents");
            return response.data;
          }
        })
        .catch((error) => {
          return {
            status: RESPONSE_STATUSES.failure,
            message: error.response.statusText,
          };
        });
    },
    // End Opportunity Actions //
    // Opportunity Date Actions //
    loadOpportunityDateList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setOpportunityDates",
        urls: programOpportunityDateUrls,
      });
    },
    fetchOpportunityDateData({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: programOpportunityDateUrls,
      });
    },
    saveOpportunityDate({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: programOpportunityDateUrls,
      });
    },
    deleteOpportunityDate({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: programOpportunityDateUrls,
      });
    },
    // End Opportunity Date Actions //
    // Effort Level Actions //
    loadEffortLevelList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setEffortLevels",
        urls: programEffortUrls,
      });
    },
    // End Opportunity Category Actions //
    // Reflection Prompt Actions //
    loadReflectionPromptList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setReflectionPrompts",
        urls: programReflectionPromptsUrls,
      });
    },
    // End Reflection Prompt Actions
  },
};
