import Rollbar from "rollbar";

export default {
  install(app, { token, environment }) {
    const rollbar = new Rollbar({
      enabled: !!token,
      accessToken: token,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: environment,
        client: {
          javascript: {
            code_version: "1.0.0",
          },
        },
      },
    });
    app.config.errorHandler = (error, vm, info) => {
      rollbar.error(error, { info });
      console.error(error);
    };
    app.provide("rollbar", rollbar);
  },
};
