<template>
  <nav title="Breadcrumb List" class="spire-breadcrumb-nav">
    <ul class="spire-breadcrumb-list unstyled-list">
      <li
        v-for="(page, index) in pages"
        :key="`breadcrumb-${index}`"
        class="spire-breadcrumb-list__item"
      >
        <a
          v-if="page.name && index < pages.length - 1"
          :href="page.url"
          class="spire-breadcrumb-list__item__link"
        >
          {{ page.name }}
        </a>
        <span v-else-if="page.name" class="spire-breadcrumb-list__item__link">
          {{ page.name }}
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "SpireBreadcrumbs",
  props: {
    pages: {
      type: Array,
      required: true,
    },
  },
};
</script>
