<template>
  <tr class="spire-table__tr">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "SpireTr",
};
</script>
