<template>
  <td class="spire-table__td" :class="[mobileClass, justifyEndClass]">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "SpireTd",
  props: {
    mobileBold: {
      type: Boolean,
      default: false,
    },
    justifyEnd: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mobileClass() {
      return this.mobileBold ? "spire-table__mobile--bold-item" : "";
    },
    justifyEndClass() {
      return this.justifyEnd ? "spire-td--justify-end" : "";
    },
  },
};
</script>
