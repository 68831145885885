import { DateTime } from "luxon";

export const CREATOR_NAME_ASCENDING = "creatorNameAscending";
export const CREATOR_NAME_DESCENDING = "creatorNameDescending";
export const DATE_ASCENDING = "dateAscending";
export const DATE_DESCENDING = "dateDescending";
export const NAME_ASCENDING = "nameAscending";
export const NAME_DESCENDING = "nameDescending";
export const FIRST_NAME_ASCENDING = "firstNameAscending";
export const FIRST_NAME_DESCENDING = "firstNameDescending";
export const LAST_NAME_ASCENDING = "lastNameAscending";
export const LAST_NAME_DESCENDING = "lastNameDescending";
export const ROLE_ASCENDING = "roleAscending";
export const ROLE_DESCENDING = "roleDescending";
export const LAST_LOGIN_AT_ASCENDING = "lastLoginAtAscending";
export const LAST_LOGIN_AT_DESCENDING = "lastLoginAtDescending";
export const AUTHOR_TYPE_ASCENDING = "authorTypeAscending";
export const AUTHOR_TYPE_DESCENDING = "authorTypeDescending";
export const STATUS_ASCENDING = "statusAscending";
export const STATUS_DESCENDING = "statusDescending";

export const CREATOR_NAME_ASCENDING_OPTION = {
  value: CREATOR_NAME_ASCENDING,
  label: "Author (A-Z)",
};
export const CREATOR_NAME_DESCENDING_OPTION = {
  value: CREATOR_NAME_DESCENDING,
  label: "Author (Z-A)",
};
export const DATE_ASCENDING_OPTION = {
  value: DATE_ASCENDING,
  label: "Date (Ascending)",
};
export const DATE_DESCENDING_OPTION = {
  value: DATE_DESCENDING,
  label: "Date (Descending)",
};
export const NAME_ASCENDING_OPTION = {
  value: NAME_ASCENDING,
  label: "Name (A-Z)",
};
export const NAME_DESCENDING_OPTION = {
  value: NAME_DESCENDING,
  label: "Name (Z-A)",
};
export const FIRST_NAME_ASCENDING_OPTION = {
  value: FIRST_NAME_ASCENDING,
  label: "First Name (A-Z)",
};
export const FIRST_NAME_DESCENDING_OPTION = {
  value: FIRST_NAME_DESCENDING,
  label: "First Name (Z-A)",
};
export const LAST_NAME_ASCENDING_OPTION = {
  value: LAST_NAME_ASCENDING,
  label: "Last Name (A-Z)",
};
export const LAST_NAME_DESCENDING_OPTION = {
  value: LAST_NAME_DESCENDING,
  label: "Last Name (Z-A)",
};
export const ROLE_ASCENDING_OPTION = {
  value: ROLE_ASCENDING,
  label: "Role (A-Z)",
};
export const ROLE_DESCENDING_OPTION = {
  value: ROLE_DESCENDING,
  label: "Role (Z-A)",
};
export const LAST_LOGIN_AT_ASCENDING_OPTION = {
  value: LAST_LOGIN_AT_ASCENDING,
  label: "Last Login At (Ascending)",
};
export const LAST_LOGIN_AT_DESCENDING_OPTION = {
  value: LAST_LOGIN_AT_DESCENDING,
  label: "Last Login At (Descending)",
};
export const AUTHOR_TYPE_ASCENDING_OPTION = {
  value: AUTHOR_TYPE_ASCENDING,
  label: "Author Type (A-Z)",
};
export const AUTHOR_TYPE_DESCENDING_OPTION = {
  value: AUTHOR_TYPE_DESCENDING,
  label: "Author Type (Z-A)",
};
export const STATUS_ASCENDING_OPTION = {
  value: STATUS_ASCENDING,
  label: "Status (A-Z)",
};
export const STATUS_DESCENDING_OPTION = {
  value: STATUS_DESCENDING,
  label: "Status (Z-A)",
};

export const SORT_FUNCTIONS = {
  [CREATOR_NAME_ASCENDING](a, b) {
    let creatorNameA = a.creator.first_name + a.creator.last_name;
    let creatorNameB = b.creator.first_name + b.creator.last_name;
    return creatorNameA.toLowerCase().localeCompare(creatorNameB.toLowerCase());
  },
  [CREATOR_NAME_DESCENDING](a, b) {
    let creatorNameA = a.creator.first_name + a.creator.last_name;
    let creatorNameB = b.creator.first_name + b.creator.last_name;
    return creatorNameB.toLowerCase().localeCompare(creatorNameA.toLowerCase());
  },
  [DATE_ASCENDING](a, b) {
    let aStart = a.activity_date ? a.activity_date.start_date : a.start_date;
    let bStart = b.activity_date ? b.activity_date.start_date : b.start_date;
    if (aStart && bStart) {
      return new Date(bStart) < new Date(aStart) ? 1 : -1;
    }
    return -1;
  },
  [DATE_DESCENDING](a, b) {
    let aStart = a.activity_date ? a.activity_date.start_date : a.start_date;
    let bStart = b.activity_date ? b.activity_date.start_date : b.start_date;
    if (aStart && bStart) {
      return new Date(bStart) > new Date(aStart) ? 1 : -1;
    }
    return -1;
  },
  [NAME_ASCENDING](a, b) {
    let item1 = a.assignment ? a.assignment : a;
    let item2 = b.assignment ? b.assignment : b;
    return item1.name.toLowerCase().localeCompare(item2.name.toLowerCase());
  },
  [NAME_DESCENDING](a, b) {
    let item1 = a.assignment ? a.assignment : a;
    let item2 = b.assignment ? b.assignment : b;
    return item2.name.toLowerCase().localeCompare(item1.name.toLowerCase());
  },
  [AUTHOR_TYPE_ASCENDING](a, b) {
    return a.source.toLowerCase().localeCompare(b.source.toLowerCase());
  },
  [AUTHOR_TYPE_DESCENDING](a, b) {
    return b.source.toLowerCase().localeCompare(a.source.toLowerCase());
  },
  [STATUS_ASCENDING](a, b) {
    return a.status.toLowerCase().localeCompare(b.status.toLowerCase());
  },
  [STATUS_DESCENDING](a, b) {
    return b.status.toLowerCase().localeCompare(a.status.toLowerCase());
  },
  [LAST_LOGIN_AT_ASCENDING](a, b) {
    let aStart = a.last_login_at;
    let bStart = b.last_login_at;
    if (aStart && bStart) {
      return new Date(bStart) < new Date(aStart) ? 1 : -1;
    }
    return -1;
  },
  [LAST_LOGIN_AT_DESCENDING](a, b) {
    let aStart = a.last_login_at;
    let bStart = b.last_login_at;
    if (aStart && bStart) {
      return new Date(bStart) > new Date(aStart) ? 1 : -1;
    }
    return -1;
  },
};

export const comparator =
  (compareFn, { asc = true, value = (x) => x }) =>
  (a, b) => {
    const aValue = value(a);
    const bValue = value(b);
    if (aValue && bValue) {
      return asc ? compareFn(aValue, bValue) : -compareFn(aValue, bValue);
    } else if (aValue) {
      return -1;
    } else if (bValue) {
      return 1;
    } else {
      return 0;
    }
  };

export const toComparator = (compareFn) => (options) =>
  comparator(compareFn, options);
export const compareStrings = toComparator((a, b) =>
  a.toLowerCase().localeCompare(b.toLowerCase()),
);
export const compareNumbers = toComparator((a, b) => a - b);

export const compareChain =
  (...compareFns) =>
  (a, b) => {
    for (let compareFn of compareFns) {
      const result = compareFn(a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };

export const compareISODateStrings = toComparator((a, b) => {
  const dtA = DateTime.fromISO(a);
  const dtB = DateTime.fromISO(b);
  return dtA < dtB ? -1 : dtA === dtB ? 0 : 1;
});
