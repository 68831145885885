import axios from "axios";

import {
  userReflectionUrls,
  userReflectionAttachFilesUrl,
  userReflectionDetachFileUrl,
} from "../urls.js";

import { engagementOptions } from "../utils/constants.js";

import { genericMixin } from "./generic.js";

const initialState = {
  isSaving: false,
  page: 0,
  reflection: {},
  enabledCompetencyIds: [],
  dirtyCompletionDate: "",
  validationErrors: {
    completed: [],
    enabled_competencies: [],
    text_comment: [],
    text_comment_draft: [],
    user_learning_objective_engagements: [],
    user_learning_objective_engagements_required: [],
  },
};

export const myReflectionModule = {
  namespaced: true,
  state: () => ({
    ...initialState,
  }),
  getters: {
    ...genericMixin.getters,
    userActivity(state, getters, rootState) {
      return rootState["user"].userActivities.find(
        (activity) => activity.submission_id === state.reflection.id,
      );
    },
    completionDate(state, getters) {
      return getters.userActivity?.completed || undefined;
    },
    textCommentDraft(state) {
      return state.reflection.text_comment_draft;
    },
    textComment(state) {
      return state.reflection.text_comment;
    },
    hasErrors(state) {
      return Object.values(state.validationErrors).some(
        (errorList) => errorList.length > 0,
      );
    },
    isSubmitted(state) {
      return !!state.reflection.submitted_at;
    },
    opportunity(state) {
      return state.reflection.id ? state.reflection.assignment : null;
    },
    taggedCompetencies(state) {
      return state.reflection.id
        ? state.reflection.assignment.learning_objectives
        : [];
    },
    taggedCompetencyIds(state) {
      return state.reflection.id
        ? state.reflection.assignment.learning_objectives.map(
            (objective) => objective.id,
          )
        : [];
    },
    userEngagements(state) {
      return state.reflection.user_learning_objective_engagements;
    },
    userEngagementDimensionIds(state, getters) {
      return getters.userEngagements.map(
        (engagement) => engagement.learning_objective_id,
      );
    },
    engagedCompetencies(state, getters, rootState, rootGetters) {
      return rootGetters["competency/baseCompetencies"].filter((competency) => {
        const dimensionIds = competency.dimensions.map(
          (dimension) => dimension.id,
        );
        return dimensionIds.some((dimensionId) =>
          getters.userEngagementDimensionIds.includes(dimensionId),
        );
      });
    },
    engagedCompetencyIds(state, getters) {
      return getters.engagedCompetencies.map((competency) => competency.id);
    },
    engagedCompetenciesWithEngagements(state, getters) {
      return getters.engagedCompetencies
        .map((competency) => ({
          ...competency,
          dimensions: competency.dimensions.map((dimension) => ({
            ...dimension,
            engagement: getters.getEngagementForDimension(dimension.id),
          })),
        }))
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        );
    },
    getEngagementForDimension: (state, getters) => (dimensionId) => {
      let engagement = getters.userEngagements.find(
        (engagement) => engagement.learning_objective_id === dimensionId,
      );
      let engagementDisplayText = engagement
        ? engagementOptions[engagement.engagement]
        : "None";
      return {
        value: engagement ? engagement.engagement : null,
        label: engagementDisplayText,
      };
    },
    missingDimensionEngagements(state, getters) {
      return (
        state.reflection.user_learning_objective_engagements.length !==
        getters.dimensionIdsForEnabledAndEngagedCompetencies.length
      );
    },
    noDimensionEngagementsGiven(state) {
      return state.reflection.user_learning_objective_engagements.length === 0;
    },
    noEnabledCompetencies(state) {
      return state.enabledCompetencyIds.length === 0;
    },
    noEngagedCompetencies(state, getters) {
      return getters.engagedCompetencies.length === 0;
    },
    enabledCompetencies(state, getters, rootState, rootGetters) {
      return state.enabledCompetencyIds.map((competencyId) =>
        rootGetters["competency/baseCompetencies"].find(
          (competency) => competency.id === competencyId,
        ),
      );
    },
    dimensionIdsForEnabledCompetencies(state, getters) {
      return [...getters.enabledCompetencies]
        .map((competency) => {
          let competencyDimensionIds = competency.dimensions.map(
            (dimension) => dimension.id,
          );
          return competencyDimensionIds;
        })
        .flat();
    },
    dimensionIdsForEngagedCompetencies(state, getters) {
      return [...getters.engagedCompetencies]
        .map((competency) => {
          let competencyDimensionIds = competency.dimensions.map(
            (dimension) => dimension.id,
          );
          return competencyDimensionIds;
        })
        .flat();
    },
    dimensionIdsForEnabledAndEngagedCompetencies(state, getters) {
      return Array.from(
        new Set([
          ...getters.dimensionIdsForEnabledCompetencies,
          ...getters.dimensionIdsForEngagedCompetencies,
        ]),
      );
    },
  },
  mutations: {
    setIsSaving(state, isSaving) {
      state.isSaving = isSaving;
    },
    setDirtyCompletionDate(state, date) {
      state.dirtyCompletionDate = date;
    },
    setReflection(state, reflection) {
      state.reflection = reflection;
    },
    jumpToPage(state, number) {
      state.page = number;
      window.scrollTo(0, 0);
    },
    addValidationError(state, { fieldName, errorMessage }) {
      state.validationErrors[fieldName].push(errorMessage);
    },
    clearValidationErrors(state) {
      state.validationErrors = {
        completed: [],
        enabled_competencies: [],
        text_comment: [],
        text_comment_draft: [],
        user_learning_objective_engagements: [],
        user_learning_objective_engagements_required: [],
      };
    },
    setEnabledCompetencyIds(state, ids) {
      state.enabledCompetencyIds = ids;
    },
  },
  actions: {
    ...genericMixin.actions,
    async loadReflection({ commit }, { programId, reflectionId }) {
      let url = userReflectionUrls.details.stringify({
        programId,
        objectId: reflectionId,
      });
      let response = await axios.get(url);
      commit("setReflection", response.data);
      if (response.data.submitted_at) {
        commit("jumpToPage", 3);
      }
    },
    async saveReflection({ dispatch, commit }, data) {
      commit("setIsSaving", true);
      let response = await dispatch("saveInstanceData", {
        urls: userReflectionUrls,
        data,
      });
      commit("setReflection", response.instance);
      setTimeout(() => {
        commit("setIsSaving", false);
      }, 1000);
      return response;
    },
    saveReflectionAttachments({ commit }, { programId, objectId, files }) {
      commit("setIsSaving", true);
      let url = userReflectionAttachFilesUrl.stringify({
        programId,
        objectId,
      });
      let formData = new FormData();
      files.forEach((file) => {
        formData.append("files[]", file);
      });
      return axios.post(url, formData).then((response) => {
        commit("setIsSaving", false);
        return response.data;
      });
    },
    removeReflectionAttachment({ commit }, { programId, objectId, fileId }) {
      commit("setIsSaving", true);
      let url = userReflectionDetachFileUrl.stringify({
        programId,
        objectId,
      });
      let formData = new FormData();
      formData.append("fileId", fileId);
      return axios.post(url, formData).then((response) => {
        commit("setIsSaving", false);
        return response.data;
      });
    },
  },
};
