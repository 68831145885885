<template>
  <block-modal ref="blockModalRef" :is-active="isActive">
    <template #button-content> Image </template>
    <template #block-modal-header> Add an image </template>
    <template #block-modal-form>
      <form-kit type="form" id="image" :actions="false" @submit="handleSubmit">
        <h3>Choose your image file</h3>
        <form-kit
          label="Choose image file"
          name="file"
          type="file"
          validation="required"
        />
        <form-kit
          label="Alternative text for this image"
          name="altText"
          type="text"
          validation="required"
          help="Enter a short description of your image. This will display if the image is
      unable to load."
        />
      </form-kit>
    </template>
    <template #footer>
      <spire-button
        @click="submitForm('image')"
        variant="primary"
        title="Add image"
      />
    </template>
  </block-modal>
</template>

<script setup>
import { ref } from "vue";
import { submitForm } from "@formkit/vue";
import BlockModal from "./BlockModal.vue";
defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["image-uploaded"]);
const blockModalRef = ref();
const handleSubmit = ({ file, altText }) => {
  // Converts the selected file to a base64 string
  // and uses that as the src attribute to avoid persisting
  // to the database
  var fileReader = new FileReader();
  fileReader.onloadend = () => {
    var src = fileReader.result;
    emit("image-uploaded", {
      src,
      altText,
      modal: blockModalRef.value,
    });
  };
  if (file.length) {
    let imageFile = file[0].file;
    fileReader.readAsDataURL(imageFile);
  }
};
</script>
