<template>
  <component
    :is="renderedHtmlTag"
    v-bind="$attrs"
    class="spire-button"
    :class="[
      classes,
      {
        'spire-button-link': href,
        'no-title': !hasTitle,
        'auto-responsive': autoResponsive,
      },
    ]"
    :href="href"
    :target="target"
    :type
  >
    <slot name="icon-left">
      <fa-icon
        v-if="hasLeftIcon"
        class="spire-button__icon-left fa"
        :icon="iconLeft"
      />
    </slot>
    <slot>
      <span v-if="shortTitleDisplay" class="spire-button__short-title">
        {{ shortTitleDisplay }}
      </span>
      <span v-if="title" class="spire-button__title">{{ title }}</span>
    </slot>
    <slot name="icon-right">
      <fa-icon
        v-if="hasRightIcon"
        class="spire-button__icon-right fa"
        :icon="iconRight"
      />
    </slot>
  </component>
</template>

<script>
// const BUTTON_SIZE_EXTRA_SMALL = "x-small";
// const BUTTON_SIZE_SMALL = "small";
const BUTTON_SIZE_NORMAL = "normal";
// const BUTTON_SIZE_LARGE = "large";
// const BUTTON_SIZE_EXTRA_LARGE = "x-large";
export default {
  props: {
    variant: {
      type: String,
      required: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    sizing: {
      type: String,
      default: BUTTON_SIZE_NORMAL,
    },
    iconLeft: {
      type: String,
      default: "",
    },
    iconRight: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    titleShort: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "button",
    },
    autoResponsive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    renderedHtmlTag() {
      return !this.href ? "button" : "a";
    },
    variantClass() {
      return `spire-button--${this.variant}`;
    },
    outlineClass() {
      return this.outlined ? "btn-outline" : "";
    },
    plainClass() {
      return this.plain ? "btn-plain" : "";
    },
    blockClass() {
      return this.block ? "btn-block" : "";
    },
    sizingClass() {
      return `btn-${this.sizing}`;
    },
    classes() {
      return [
        this.variantClass,
        this.outlineClass,
        this.plainClass,
        this.blockClass,
        this.sizingClass,
      ];
    },
    hasLeftIcon() {
      return this.iconLeft != "";
    },
    hasRightIcon() {
      return this.iconRight != "";
    },
    hasTitle() {
      return this.title != "";
    },
    shortTitleDisplay() {
      return this.titleShort ? this.titleShort : this.title;
    },
  },
};
</script>
