<template>
  <block-modal ref="blockModal" :is-active="isActive">
    <template #button-content> Link </template>
    <template #block-modal-header> Add a link </template>
    <template #block-modal-form>
      <p v-if="!selectedText">
        No link text found. Please select the text you want to convert to a link
        first.
      </p>
      <form-kit
        v-else
        type="form"
        id="link"
        :actions="false"
        @submit="emitAddLink"
      >
        <h3>Selected text</h3>
        <p>
          This is the text you selected; it is read-only. To change it, close
          this modal and reselect the text you want to apply a link to.
        </p>
        <form-kit
          label="Text"
          name="text"
          type="text"
          :value="selectedText"
          readonly
        />
        <h3 class="mt-2">Provide the URL you want to link to</h3>
        <p>
          Provide an external URL that starts with <strong>http://</strong> or
          <strong>https://</strong>
        </p>
        <form-kit
          label="Url"
          name="href"
          type="url"
          validation="required|isValidUrl|url"
          wrapper-class="url-input_spire"
        />
      </form-kit>
    </template>
    <template #footer>
      <spire-button
        v-if="selectedText"
        type="button"
        variant="primary"
        title="Add link"
        @click="$formkit.submit('link')"
      />
      <spire-button
        v-else
        type="button"
        variant="primary"
        title="Close"
        @click="() => $refs.blockModal.closeModal()"
      />
    </template>
  </block-modal>
</template>

<script>
import BlockModal from "./BlockModal.vue";

export default {
  name: "LinkModal",
  props: {
    selectedText: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["add-link"],
  components: {
    BlockModal,
  },
  methods: {
    emitAddLink(data) {
      let allData = {
        ...data,
        modal: this.$refs.blockModal,
      };
      this.$emit("add-link", allData);
    },
  },
};
</script>
