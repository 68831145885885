<template>
  <div class="spire-button-group" :class="wrapClass">
    <slot>
      <div class="spire-button-group__left">
        <slot name="left"></slot>
      </div>
      <div class="spire-button-group__right">
        <slot name="right"></slot>
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  props: {
    noWrap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    wrapClass() {
      return this.noWrap ? "no-wrap" : "";
    },
  },
};
</script>
