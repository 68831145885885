import UrlPattern from "url-pattern";

export const PROGRAM_COMPETENCIES_URL = new UrlPattern(
  "/programs/:programId/competencies",
);
export const PROGRAM_COMPETENCY_DETAIL_URL = new UrlPattern(
  "/programs/:programId/competencies/:competencyId",
);

export const ADMIN_LEVEL_REQUESTS_URL = new UrlPattern(
  "/admin/programs/:programId/level-requests",
);
export const ADMIN_PROGRAM_DASHBOARD_URL = new UrlPattern(
  "/admin/programs/:programId/dashboard",
);
export const ADMIN_COMPETENCIES_URL = new UrlPattern(
  "/admin/programs/:programId/competencies",
);
export const ADMIN_COMPETENCY_DETAIL_URL = new UrlPattern(
  "/admin/programs/:programId/competencies/:competencyId",
);
export const ADMIN_COMPETENCY_EDIT_URL = new UrlPattern(
  "/admin/programs/:programId/competencies/:competencyId/edit",
);

export const routes = [
  {
    name: "competencies",
    pattern: PROGRAM_COMPETENCIES_URL,
  },
  {
    name: "admin/dashboard",
    pattern: ADMIN_PROGRAM_DASHBOARD_URL,
  },
  {
    name: "competency-detail",
    pattern: PROGRAM_COMPETENCY_DETAIL_URL,
  },
  {
    name: "admin/competencies",
    pattern: ADMIN_COMPETENCIES_URL,
  },
  {
    name: "admin/competency-detail",
    pattern: ADMIN_COMPETENCY_DETAIL_URL,
  },
  {
    name: "admin/competency-edit",
    pattern: ADMIN_COMPETENCY_EDIT_URL,
  },
  {
    name: "admin/level-requests",
    pattern: ADMIN_LEVEL_REQUESTS_URL,
  },
];
