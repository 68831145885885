<template>
  <div class="spire-table__wrapper">
    <table class="spire-table spire-table__mobile-responsive">
      <thead>
        <tr>
          <slot name="table_headings"></slot>
        </tr>
      </thead>
      <slot name="table_body">
        <tbody>
          <slot name="table_rows"></slot>
        </tbody>
      </slot>
    </table>
  </div>
</template>

<script>
export default {
  name: "SpireTable",
  props: {
    breakPoint: {
      type: [Number, String],
      default: "800",
    },
    tableClasses: {
      type: String,
      default: "",
    },
    theadClasses: {
      type: String,
      default: "",
    },
    tbodyClasses: {
      type: String,
      default: "",
    },
  },
  computed: {
    showBreakPointClass() {
      return `show--${this.breakPoint}`;
    },
    hideBreakPointClass() {
      return `hide--${this.breakPoint}`;
    },
  },
};
</script>
