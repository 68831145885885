import { programOpportunityUrls } from "../urls.js";

import { genericMixin } from "./generic.js";

const initialState = {
  opportunity: {},
};

export const opportunityDetailModule = {
  namespaced: true,
  state: () => ({
    ...initialState,
  }),
  getters: {
    ...genericMixin.getters,
    opportunityDates(state) {
      return state.opportunity.activity_dates;
    },
    currentOpportunityDates(state, getters) {
      let now = new Date();
      return getters.opportunityDates.filter(
        (date) => new Date(date.end_date) > now,
      );
    },
    pastOpportunityDates(state, getters) {
      let now = new Date();
      return getters.opportunityDates.filter(
        (date) => new Date(date.end_date) <= now,
      );
    },
  },
  mutations: {
    setOpportunity(state, data) {
      state.opportunity = data;
    },
    updateOpportunityDate(state, { data }) {
      let index = state.opportunity.activity_dates.findIndex(
        (date) => date.id === data.id,
      );
      let notFound = index === -1;
      if (notFound) {
        state.opportunity.activity_dates.push(data);
      } else {
        state.opportunity.activity_dates.splice(index, 1, data);
      }
    },
    updateOpportunityTimestamp(state, timestamp) {
      state.opportunity.updated_at = timestamp;
    },
    updateOpportunityId(state, id) {
      state.opportunity.id = id;
    },
    deleteOpportunityDate(state, { id }) {
      let index = state.opportunity.activity_dates.findIndex(
        (date) => date.id === id,
      );
      let found = index >= 0;
      if (found) {
        state.opportunity.activity_dates.splice(index, 1);
      }
    },
  },
  actions: {
    ...genericMixin.actions,
    fetchOpportunityData({ dispatch, commit }, data) {
      dispatch("fetchInstanceData", {
        data: data,
        urls: programOpportunityUrls,
      }).then((opportunity) => {
        commit("setOpportunity", opportunity);
      });
    },
  },
};
