<template>
  <th class="spire-table__th" :class="justifyEndClass">
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: "SpireTh",
  props: {
    justifyEnd: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    justifyEndClass() {
      return this.justifyEnd ? "spire-th--justify-end" : "";
    },
  },
};
</script>
