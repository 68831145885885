import { genericMixin } from "./generic.js";

import {
  programLevelRequestUrls,
  programLevelRequestReviewsUrls,
} from "../urls.js";
import _ from "lodash";

export const levelUpRequestDetailsModule = {
  namespaced: true,
  state: () => ({
    request: {},
  }),
  getters: {
    ...genericMixin.getters,
    toLevel(state) {
      return state.request.to_level;
    },
    fromLevel(state) {
      return _.get(state.request, "from_level") || { name: "Not started" };
    },
    reviewerPrompts(state) {
      return state.request.to_level.reflection_prompts.filter(
        (prompt) => prompt.audience === "reviewer",
      );
    },
    assessments(state) {
      const { current_review, past_reviews } = state.request;
      return current_review
        ? past_reviews.concat(current_review)
        : past_reviews;
    },
    dimension(state, getters, rootState, rootGetters) {
      return getters.toLevel
        ? rootGetters["competency/programDimensions"].find(
            (dimension) => dimension.id === getters.toLevel.objective_id,
          )
        : null;
    },
  },
  actions: {
    ...genericMixin.actions,
    async fetchRequestDetails({ dispatch, commit }, id) {
      const data = await dispatch("fetchInstanceDetailsData", {
        urls: programLevelRequestUrls,
        data: { id },
      });
      commit("setLevelUpRequest", data);
    },
    async saveReview({ state, dispatch }, review) {
      const data = { ...review, requestId: state.request.id };
      await dispatch("saveInstanceData", {
        urls: programLevelRequestReviewsUrls,
        data,
      });
    },
    async submitReview({ state, dispatch }, review) {
      const data = { ...review, requestId: state.request.id };
      await dispatch("saveInstanceData", {
        urls: programLevelRequestReviewsUrls,
        data,
      });
    },
  },
  mutations: {
    setLevelUpRequest(state, data) {
      state.request = data;
    },
  },
};
