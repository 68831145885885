import { apiClient } from "@/programs/services/ApiClientV2.js";
import URLPattern from "url-pattern";
import { API } from "@/programs/types";

type TransformResponseFunc<In, Out> = (input: In) => Out;

export const restfulCreateTransform =
  <Params, APIResponseData, FormattedData, Payload>(
    pattern: URLPattern,
    transform: TransformResponseFunc<APIResponseData, FormattedData>,
  ) =>
  async (
    params: Params,
    payload: Payload,
  ): Promise<API.PostResponse<FormattedData>> => {
    const url = pattern.stringify(params);
    const { data } = await apiClient.post<API.PostResponse<APIResponseData>>(
      url,
      payload,
    );
    if ("errors" in data) {
      return data;
    }
    return {
      ...data,
      data: transform(data.data),
    };
  };

export const restfulCreate =
  <Params, Response, Payload>(pattern: URLPattern) =>
  async (
    params: Params,
    payload: Payload,
  ): Promise<API.PostResponse<Response>> => {
    const url = pattern.stringify(params);
    const { data } = await apiClient.post<API.PostResponse<Response>>(
      url,
      payload,
    );
    return data;
  };

export const restfulShow =
  <Params, ResponseData, QueryParams=void>(pattern: URLPattern) =>
  async (params: Params, query?: QueryParams): Promise<API.GetResponse<ResponseData>> => {
    const url = pattern.stringify(params);
    const { data } = await apiClient.get<API.GetResponse<ResponseData>>(url, { params: query });
    return data;
  };

export const restfulUpdate =
  <Params, ResponseData, Payload>(pattern: URLPattern) =>
  async (
    params: Params,
    payload: Payload,
  ): Promise<API.PatchResponse<ResponseData>> => {
    const url = pattern.stringify(params);
    const { data } = await apiClient.patch<API.PatchResponse<ResponseData>>(
      url,
      payload,
    );
    return data;
  };

export const restfulDelete =
  <Params>(pattern: URLPattern) =>
  async (params: Params): Promise<API.DeleteResponse> => {
    const url = pattern.stringify(params);
    const { data } = await apiClient.delete<API.DeleteResponse>(url);
    return data;
  };

export const restfulShowPaginated =
  <URLParams, ResponseData>(pattern: URLPattern) =>
  async (
    urlParams: URLParams,
    pagination: API.PaginationQueryParams,
  ): Promise<API.GetPaginatedResponse<ResponseData>> => {
    const url = pattern.stringify(urlParams);
    const { data } = await apiClient.get<
      API.GetPaginatedResponse<ResponseData>
    >(url, {
      params: {
        ...pagination,
      },
    });
    return data;
  };

export const restfulSearchableShowPaginated =
  <URLParams, ResponseData>(pattern: URLPattern) =>
  async (
    urlParams: URLParams,
    pagination: API.PaginationQueryParams,
    search?: object,
  ): Promise<API.GetPaginatedResponse<ResponseData>> => {
    const url = pattern.stringify(urlParams);
    const { data } = await apiClient.get<
      API.GetPaginatedResponse<ResponseData>
    >(url, {
      params: {
        ...pagination,
        ...(search || {}),
      },
    });
    return data;
  };

export const restfulUpdateForm =
  <Params, ResponseData, Payload>(pattern: URLPattern) =>
  async (
    params: Params,
    payload: Payload,
  ): Promise<API.PatchResponse<ResponseData>> => {
    const url = pattern.stringify(params);
    const { data } = await apiClient.patchForm<API.PatchResponse<ResponseData>>(
      url,
      payload,
    );
    return data;
  };
