<template>
  <div class="rich-text-editor">
    <div class="menububble">
      <button
        type="button"
        class="menububble__button"
        :class="{ 'is-active': tipTapEditor.isActive('bold') }"
        @click="tipTapEditor.chain().focus().toggleBold().run()"
        aria-label="Bold"
      >
        B
      </button>
      <button
        type="button"
        class="menububble__button"
        :class="{ 'is-active': tipTapEditor.isActive('italic') }"
        @click="tipTapEditor.chain().focus().toggleItalic().run()"
        aria-label="Italics"
      >
        I
      </button>
      <button
        v-if="context.allowHeaders"
        type="button"
        class="menububble__button"
        :class="{ 'is-active': tipTapEditor.isActive('heading', { level: 2 }) }"
        @click="tipTapEditor.chain().focus().toggleHeading({ level: 2 }).run()"
        aria-label="Heading 2"
      >
        H2
      </button>
      <button
        v-if="context.allowHeaders"
        type="button"
        class="menububble__button"
        :class="{ 'is-active': tipTapEditor.isActive('heading', { level: 3 }) }"
        @click="tipTapEditor.chain().focus().toggleHeading({ level: 3 }).run()"
        aria-label="Heading 3"
      >
        H3
      </button>
      <button
        v-if="context.allowLists"
        type="button"
        class="menububble__button"
        :class="{ 'is-active': tipTapEditor.isActive('bulletList') }"
        @click="tipTapEditor.chain().focus().toggleBulletList().run()"
        aria-label="Bulleted List"
      >
        List
      </button>
      <link-modal
        v-if="context.allowLinks"
        @add-link="addLink"
        :is-active="tipTapEditor.isActive('link')"
        :selected-text="selectedText"
      />
      <button
        v-if="context.allowLinks"
        type="button"
        class="menububble__button"
        :class="{
          'is-active': tipTapEditor.isActive('link'),
          disabled: !tipTapEditor.isActive('link'),
        }"
        @click="tipTapEditor.commands.unsetLink()"
      >
        Unlink
      </button>
      <image-modal
        v-if="context.allowImages"
        @image-uploaded="addImage"
        :is-active="tipTapEditor.isActive('image')"
      />
      <video-embed-modal
        v-if="context.allowVideos"
        @add-video="addVideo"
        :is-active="tipTapEditor.isActive('video_embed')"
      />
    </div>
    <editor-content
      :editor="tipTapEditor"
      v-model="content"
      :class="editorClass"
    />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";

import ImageModal from "@/programs/components/richText/ImageModal.vue";
import LinkModal from "@/programs/components/richText/LinkModal.vue";
import VideoEmbedBlock from "@/programs/components/richText/extensions/VideoEmbedBlock.js";
import VideoEmbedModal from "@/programs/components/richText/VideoEmbedModal.vue";

export default {
  name: "RichTextEditor",
  components: {
    EditorContent,
    ImageModal,
    LinkModal,
    VideoEmbedModal,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tipTapEditor: null,
    };
  },
  computed: {
    selectedText() {
      const { from, to, empty } = this.tipTapEditor.state.selection;
      return empty
        ? ""
        : this.tipTapEditor.state.doc.textBetween(from, to, " ");
    },
    content: {
      get() {
        return this.context._value;
      },
      set(value) {
        return this.context.node.input(value);
      },
    },
    editorClass() {
      return this.context.editorClass;
    },
  },
  created() {
    this.tipTapEditor = new Editor({
      extensions: [
        Link.extend({
          inclusive: false,
        }).configure({
          openOnClick: false,
        }),
        StarterKit,
        Underline,
        VideoEmbedBlock,
        Image.configure({
          allowBase64: true,
        }),
      ],
      onUpdate: this.handleUpdate,
      content: this.context._value,
    });
  },
  beforeUnmount() {
    this.tipTapEditor.destroy();
  },
  methods: {
    handleUpdate({ editor }) {
      this.context.node.input(editor.getHTML());
    },
    addLink({ href, modal }) {
      this.tipTapEditor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href })
        .run();
      modal.closeModal();
    },
    addImage({ src, altText, modal }) {
      this.tipTapEditor.chain().focus().setImage({ src, alt: altText }).run();
      modal.closeModal();
    },
    addVideo({ src, modal }) {
      this.tipTapEditor.chain().focus().setVideoEmbed({ src }).run();
      modal.closeModal();
    },
  },
  watch: {
    context(context) {
      const contentChanged = this.tipTapEditor.getHTML() !== context._value;
      if (contentChanged) {
        this.tipTapEditor.commands.setContent(context._value, false);
      }
    },
  },
};
</script>
