<template>
  <div class="combobox-selected-options-column">
    <p><slot name="list-title"></slot></p>
    <ul class="unstyled-list">
      <formulate-combobox-selected-option
        v-for="option in selectedOptions"
        :key="`selection-${option.value}`"
        :option="option"
        @delete-option="deleteOption"
      />
    </ul>
  </div>
</template>

<script>
import FormulateComboboxSelectedOption from "./FormulateComboboxSelectedOption.vue";
export default {
  props: {
    selectedOptions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["delete-option"],
  components: {
    FormulateComboboxSelectedOption,
  },
  methods: {
    deleteOption(value) {
      this.$emit("delete-option", value);
    },
  },
};
</script>
