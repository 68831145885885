import {
  programUnlockConditionsUrls,
  programExternalReferencesUrls,
} from "../urls.js";

import { genericMixin } from "./generic.js";

const initialState = {
  unlockConditions: [],
  externalItemReferences: [],
};

export const unlockModule = {
  namespaced: true,
  state: () => ({
    ...initialState,
  }),
  getters: {
    ...genericMixin.getters,
  },
  mutations: {
    setUnlockConditions(state, { data }) {
      state.unlockConditions = data;
    },
    setExternalItemReferences(state, { data }) {
      state.externalItemReferences = data;
    },
  },
  actions: {
    ...genericMixin.actions,
    // Unlock Condition Actions //
    loadUnlockConditionList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setUnlockConditions",
        urls: programUnlockConditionsUrls,
      });
    },
    fetchUnlockCondition({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: programUnlockConditionsUrls,
      });
    },
    saveUnlockCondition({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: programUnlockConditionsUrls,
      });
    },
    deleteUnlockCondition({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: programUnlockConditionsUrls,
      });
    },
    // End Unlock Condition Actions //
    // External Reference Actions //
    loadExternalReferenceList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setExternalItemReferences",
        urls: programExternalReferencesUrls,
      });
    },
    fetchExternalReference({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: programExternalReferencesUrls,
      });
    },
    saveExternalReference({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: programExternalReferencesUrls,
      });
    },
    deleteExternalReference({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: programExternalReferencesUrls,
      });
    },
    // End External Reference Actions //
  },
};
