import { computed } from "vue";
import { isNil, keyBy } from "lodash";

export const useVMSFormKitShim = (
  context,
  options,
  config = { replaceMissingValue: (x) => x },
) => {
  const optionsByValue = computed(() => {
    const allOptions = context.groupValues?.length
      ? options.value.flatMap((optionGroup) => optionGroup[context.groupValues])
      : options.value;
    return keyBy(allOptions, "value");
  });
  const toOption = (value) => {
    return optionsByValue.value[value] || config.replaceMissingValue(value);
  };
  const toValue = ({ value }) => value;
  const inputValueToFormKitValue = (inputValue) => {
    if (isNil(inputValue)) return inputValue;
    return Array.isArray(inputValue)
      ? inputValue.map(toValue)
      : toValue(inputValue);
  };

  const formKitValueToInputValue = (formKitValue) => {
    if (isNil(formKitValue)) return formKitValue;
    return Array.isArray(formKitValue)
      ? formKitValue.map(toOption)
      : toOption(formKitValue);
  };

  const value = computed({
    get: () => {
      const formKitSelectedValues = context._value;
      return formKitValueToInputValue(formKitSelectedValues);
    },
    set: (vmsSelectedOptions) => {
      const selectedValues = inputValueToFormKitValue(vmsSelectedOptions);
      context.node.input(selectedValues);
    },
  });
  return {
    value,
  };
};
