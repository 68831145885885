<script setup>
import { groupBy, sortBy } from "lodash";
import { computed, ref, onMounted } from "vue";
import VueMultiselect from "vue-multiselect";
import { getLevelOptions } from "@/programs/services/LevelService.ts";
import { useVMSFormKitShim } from "@/programs/composables/useVMSFormKitShim.js";
import LevelSelectTag from "@/programs/components/inputs/LevelSelectTag.vue";

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const multiple = props.context.multiple;
const closeOnSelect = props.context.closeOnSelect || multiple === false;
const searchable = props.context.searchable;
const allowEmpty = props.context.allowEmpty;
const levelType = props.context.levelType;

const options = ref([]);
const loading = ref(false);
const error = ref(false);

const placeholder = computed(() => {
  if (error.value === true) {
    return "Failed to load levels";
  }
  return props.context.placeholder || "Type to search levels";
});

const { value } = useVMSFormKitShim(props.context, options, {
  replaceMissingValue: () => null,
});
const optionGroups = computed(() => {
  const groups = groupBy(options.value, "objectiveName");
  return Object.entries(groups)
    .map(([objective, levels]) => ({
      objective,
      levels: sortBy(levels, "order"),
    }))
    .sort((a, b) => a.objective.localeCompare(b.objective));
});
const filterByLevelType = (level) => {
  return !levelType || level.objectiveType === levelType;
};

onMounted(async () => {
  error.value = false;
  loading.value = true;
  try {
    const response = await getLevelOptions({
      programId: props.context.programId,
    });
    options.value = response.data.filter(filterByLevelType);
  } catch (err) {
    error.value = true;
    throw err;
  } finally {
    loading.value = false;
  }
});
</script>
<template>
  <vue-multiselect
    v-model="value"
    :show-labels="true"
    :multiple="multiple"
    :searchable="searchable"
    :close-on-select="closeOnSelect"
    :options="optionGroups"
    :disabled="context.disabled"
    :loading="loading"
    :name="context.node.name"
    :id="context.id"
    :placeholder="placeholder"
    :allow-empty="allowEmpty"
    label="objectiveName"
    track-by="value"
    open-direction="auto"
    v-bind="$attrs"
    class="vue-multiselect"
    group-label="objective"
    group-values="levels"
  >
    <template #singleLabel="{ option }">
      <level-select-tag :option="option" />
    </template>
    <template #tag="{ option }">
      <span class="multiselect__tag">
        <level-select-tag :option="option" />
      </span>
    </template>
    <template #option="{ option }">
      <template v-if="option.$groupLabel">{{ option.$groupLabel }}</template>
      <template v-else>
        <b>Level {{ option.order }}</b> {{ option.name }}
      </template>
    </template>
  </vue-multiselect>
</template>
