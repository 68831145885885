import { LEVELS_API_V2_URLS } from "@/programs/urls.js";
import { API } from "@/programs/types.ts";
import { restfulShow } from "@/programs/services/ServiceHelpers.ts";

interface CollectionURLParams {
  programId: number;
}

export const getLevelOptions = restfulShow<
  CollectionURLParams,
  API.Admin.LevelOption[]
>(LEVELS_API_V2_URLS.options);
