<template>
  <div class="spire-date-filter-wrapper" v-click-outside="hide">
    <spire-button
      variant="secondary"
      class="spire-date-filter__trigger"
      :class="triggerStatusClass"
      @click="show = true"
      :icon-right="iconRight"
      >{{ triggerText }}</spire-button
    >
    <div v-if="show" class="spire-date-filter__date-window">
      <form-kit type="form" v-model="formData" @submit="handleSubmit">
        <div class="flex-2">
          <form-kit
            type="FormKitDatePicker"
            name="after_date"
            id="after_date"
            label="Starting"
            mode="date"
            :inline="true"
          />
          <form-kit
            type="FormKitDatePicker"
            name="before_date"
            id="before_date"
            label="Ending"
            mode="date"
            :inline="true"
          />
        </div>
        <template #actions>
          <spire-button-group class="mb-1">
            <template #left>
              <spire-button variant="primary" type="submit">Apply</spire-button>
              <spire-button variant="secondary" @click="hide"
                >Close</spire-button
              >
            </template>
            <template #right>
              <spire-button variant="warning" @click="clearFormData"
                >Clear</spire-button
              >
            </template>
          </spire-button-group>
        </template>
      </form-kit>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "SpireDateRangeFilter",
  props: {
    triggerText: {
      type: String,
      default: "Submission Date",
    },
    instanceData: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["apply"],
  data() {
    return {
      show: false,
      formData: cloneDeep(this.instanceData),
    };
  },
  computed: {
    iconRight() {
      return this.show ? "fa-caret-up" : "fa-caret-down";
    },
    triggerStatusClass() {
      return this.hasDates ? "options-selected" : "";
    },
    hasDates() {
      return this.formData.after_date || this.formData.before_date;
    },
  },
  methods: {
    hide() {
      this.show = false;
    },
    setFormData() {
      let instanceDataExists = Object.keys(this.instanceData);
      if (instanceDataExists) {
        let afterTimeStamp = this.instanceData.after_date;
        this.formData.after_date = afterTimeStamp || null;
        let beforeTimeStamp = this.instanceData.before_date;
        this.formData.before_date = beforeTimeStamp || null;
      }
    },
    clearFormData() {
      this.formData.after_date = null;
      this.formData.before_date = null;
      this.show = false;
      this.$emit("apply", this.formData);
    },
    handleSubmit() {
      this.$emit("apply", this.formData);
      this.show = false;
    },
  },
  watch: {
    instanceData: {
      handler() {
        this.setFormData();
      },
      deep: true,
    },
  },
};
</script>
