import {
  copyDimension,
  getCompetencyDetail,
  getCompetencyActivities,
} from "@/programs/services/CompetencyService.js";

const ADD_DIMENSION = "ADD_DIMENSION";
const SET_COMPETENCY = "SET_COMPETENCY";
const SET_ACTIVITIES = "SET_ACTIVITIES";

export const competencyDetailModule = {
  namespaced: true,
  state: () => ({
    competency: {},
    activities: {
      data: [],
      metadata: {},
    },
  }),
  getters: {
    opportunities(state) {
      return state.competency.assignments || [];
    },
    activities(state) {
      return state.activities.data;
    },
    activitiesPagination(state) {
      return state.activities.metadata;
    },
  },
  actions: {
    async fetchCompetency({ commit }, { programId, competencyId }) {
      const response = await getCompetencyDetail(programId, competencyId);
      commit(SET_COMPETENCY, response.data);
    },
    async fetchActivities({ commit }, { programId, competencyId, page }) {
      const data = await getCompetencyActivities(programId, competencyId, page);
      commit(SET_ACTIVITIES, data);
    },
    async copyDimension({ commit }, { programId, dimensionId }) {
      const response = await copyDimension(programId, dimensionId);
      if (response.status === 200) {
        commit(ADD_DIMENSION, response.data);
      }
      return response;
    },
  },
  mutations: {
    [ADD_DIMENSION]: function (state, dimension) {
      const dimensions = state.competency.dimensions;
      const index = dimensions.findIndex((d) => d.name > dimension.name);
      if (index >= 0) {
        dimensions.splice(index, 0, dimension);
      } else {
        dimensions.push(dimension);
      }
    },
    [SET_COMPETENCY]: function (state, competency) {
      state.competency = competency;
    },
    [SET_ACTIVITIES]: function (state, activities) {
      state.activities = activities;
    },
  },
};
