<template>
  <div
    class="competency-container"
    :class="{
      'competency-card--selected interactive-selected': isSelected,
    }"
  >
    <input
      type="checkbox"
      class="sr-only hidden-checkbox"
      :id="`option-${option.value}`"
      :value="option.value"
      :checked="isSelected"
      @change="handleChange($event)"
    />
    <label :for="`option-${option.value}`" class="competency-card">
      <div class="main-details">
        <p class="main-details__competency-supertitle">Competency:</p>
        <h2 class="main-details__competency-header">
          <fa-icon
            v-if="isSelected"
            class="competency-card__checkmark"
            :icon="['fa', 'check-square']"
          />
          <fa-icon
            v-else
            class="competency-card__checkmark"
            :icon="['far', 'square']"
          />
          {{ option.label }}
        </h2>

        <div v-html="option.description" class="description"></div>
        <spire-iframe
          v-if="option.youtubeUrl"
          :src="option.youtubeUrl"
          class="mt-1 competency-video-iframe"
        />
      </div>
      <div v-if="showDimensions" class="dimensions">
        <strong>Includes dimensions:</strong>
        <ul class="dimension-list mt-1 ml-0">
          <li
            class="dimension"
            v-for="(dimension, dIndex) in option.dimensions"
            :key="dIndex"
          >
            {{ dimension.name }}
          </li>
        </ul>
      </div>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { API } from "@/programs/types.ts";

const props = defineProps<{
  option: API.Student.PreferenceExerciseOption;
  selectedOptionValues: number[];
  showDimensions: boolean;
}>();

const emit = defineEmits(["updateSelectedOptionValues"]);

const isSelected = computed(() =>
  props.selectedOptionValues.includes(Number(props.option.value)),
);

const handleChange = (event) => {
  let newOptionValues = [...props.selectedOptionValues];
  if (event.target.checked) {
    newOptionValues.push(props.option.value);
  } else {
    newOptionValues = newOptionValues.filter(
      (value) => value !== props.option.value,
    );
  }
  emit("updateSelectedOptionValues", newOptionValues);
};
</script>
