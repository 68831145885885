import { library } from "@fortawesome/fontawesome-svg-core";

// ********* Please add new icons alphabetically **********

import { faLinkedin, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faSquare as faSquareRegular } from "@fortawesome/free-regular-svg-icons";
import {
  faAnglesUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsToDot,
  faArrowTrendUp,
  faArrowTurnUp,
  faArrowUp,
  faAsterisk,
  faAward,
  faBan,
  faBars,
  faBullseye,
  faCalendar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckSquare,
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faClose,
  faCloudDownload,
  faCrosshairs,
  faDownload,
  faEdit,
  faEllipsis,
  faEye,
  faFileAlt,
  faFlag,
  faFilter,
  faGear,
  faGlobe,
  faGripLines,
  faGripVertical,
  faHourglassEnd,
  faHourglassStart,
  faInfo,
  faInfoCircle,
  faLayerGroup,
  faLink,
  faLocationDot,
  faLock,
  faMagnifyingGlass,
  faPlay,
  faPlus,
  faRectangleXmark,
  faReply,
  faRightFromBracket,
  faSignal,
  faSignOutAlt,
  faSitemap,
  faSpinner,
  faSquare,
  faStickyNote,
  faStopwatch,
  faTrash,
  faTriangleExclamation,
  faUpload,
  faUser,
  faVideo,
  faVideoSlash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAnglesUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsToDot,
  faArrowTrendUp,
  faArrowTurnUp,
  faArrowUp,
  faAsterisk,
  faAward,
  faBan,
  faBars,
  faBullseye,
  faCalendar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckSquare,
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faClose,
  faCloudDownload,
  faCrosshairs,
  faDownload,
  faEdit,
  faEllipsis,
  faEye,
  faFileAlt,
  faFilter,
  faFlag,
  faGear,
  faGlobe,
  faGripLines,
  faGripVertical,
  faHourglassEnd,
  faHourglassStart,
  faInfo,
  faInfoCircle,
  faLayerGroup,
  faLink,
  faLinkedin,
  faLocationDot,
  faLock,
  faMagnifyingGlass,
  faPlay,
  faPlus,
  faRectangleXmark,
  faReply,
  faRightFromBracket,
  faSignal,
  faSignOutAlt,
  faSitemap,
  faSpinner,
  faSquare,
  faSquareRegular,
  faStickyNote,
  faStopwatch,
  faTrash,
  faTriangleExclamation,
  faUpload,
  faUser,
  faVideo,
  faVideoSlash,
  faXmark,
  faXTwitter,
);

/* add some free styles */

export default library;
