import {
  programCohortUrls,
  programTimeframeUrls,
  programIntegrationUrls,
  programAnnouncementUrls,
  programReflectionPromptsUrls,
  paginatedProgramTimeframesListUrl,
  programTimeframesListUrl,
} from "../urls.js";
import { mappedProgramUserRoles } from "../utils/constants.js";
import { genericMixin } from "./generic.js";
import { getScheduledAnnouncements } from "@/programs/services/ScheduledAnnouncementService.js";

const initialState = {
  programCohorts: [],
  programTimeframes: [],
  programUserRoles: ["student", "professor", "gsi", "observer"],
  programIntegrations: [],
  programAnnouncements: [],
  programReflectionPrompts: [],
  programScheduledAnnouncements: [],
};

export const programModule = {
  namespaced: true,
  state: () => ({
    ...initialState,
  }),
  getters: {
    ...genericMixin.getters,
    cohorts(state) {
      const { data = [] } = state.programCohorts;
      return data.map((cohort) => ({
        ...cohort,
        url: "",
      }));
    },
    cohortsPagination(state) {
      return state.programCohorts.metadata || {};
    },
    getTimeframeById: (state) => (timeframeId) => {
      return state.programTimeframes.find(
        (timeframe) => timeframe.id === timeframeId,
      );
    },
    timeframes(state) {
      const { data = [] } = state.programTimeframes;
      return data.map((timeframe) => ({
        ...timeframe,
        url: "",
      }));
    },
    timeframesPagination(state) {
      return state.programTimeframes.metadata || {};
    },
    programUserRoleOptions(state) {
      return state.programUserRoles.map((role) => ({
        value: mappedProgramUserRoles[role]["value"],
        label: mappedProgramUserRoles[role]["label"],
      }));
    },
    alphabetizedRoleOptions(state, getters) {
      return getters.programUserRoleOptions.sort((a, b) => {
        return b.label.toLowerCase() < a.label.toLowerCase() ? 1 : -1;
      });
    },
  },
  mutations: {
    ...genericMixin.mutations,
    setProgramCohorts(state, { data }) {
      state.programCohorts = data;
    },
    setProgramTimeframes(state, { data }) {
      state.programTimeframes = data;
    },
    setProgramIntegrations(state, { data }) {
      state.programIntegrations = data;
    },
    setProgramAnnouncements(state, { data }) {
      state.programAnnouncements = data;
    },
    setReflectionPrompts(state, { data }) {
      state.programReflectionPrompts = data;
    },
    setProgramScheduledAnnouncements(state, { data }) {
      state.programScheduledAnnouncements = data;
    },
  },
  actions: {
    // Generic Actions //
    ...genericMixin.actions,
    // Integration Actions //
    loadIntegrationList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setProgramIntegrations",
        urls: programIntegrationUrls,
      });
    },
    fetchIntegrationData({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: programIntegrationUrls,
      });
    },
    saveIntegration({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: programIntegrationUrls,
      });
    },
    deleteIntegration({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: programIntegrationUrls,
      });
    },
    // Announcement Actions //
    loadAnnouncementList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setProgramAnnouncements",
        urls: programAnnouncementUrls,
      });
    },
    fetchAnnouncementData({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: programAnnouncementUrls,
      });
    },
    saveAnnouncement({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: programAnnouncementUrls,
      });
    },
    deleteAnnouncement({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: programAnnouncementUrls,
      });
    },
    // Cohort Actions //
    loadCohortList({ dispatch }, params) {
      dispatch("loadObjectList", {
        mutation: "setProgramCohorts",
        urls: programCohortUrls,
        params,
      });
    },
    fetchCohortData({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: programCohortUrls,
      });
    },
    fetchCohortDetails({ dispatch }, data) {
      return dispatch("fetchInstanceDetailsData", {
        data: data,
        urls: programCohortUrls,
      });
    },
    saveCohort({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: programCohortUrls,
      });
    },
    deleteCohort({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: programCohortUrls,
      });
    },
    // Timeframe Actions //
    loadTimeframeList({ dispatch }, params = {}) {
      const urls = {
        ...programTimeframeUrls,
        list: params.paginated
          ? paginatedProgramTimeframesListUrl
          : programTimeframesListUrl,
      };
      return dispatch("loadObjectList", {
        mutation: "setProgramTimeframes",
        urls,
        params,
      });
    },
    fetchTimeframeData({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: programTimeframeUrls,
      });
    },
    saveTimeframe({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: programTimeframeUrls,
      });
    },
    deleteTimeframe({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: programTimeframeUrls,
      });
    },
    // Reflection Prompts Actions //
    loadReflectionPromptsList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setReflectionPrompts",
        urls: programReflectionPromptsUrls,
      });
    },
    fetchReflectionPromptData({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: programReflectionPromptsUrls,
      });
    },
    saveReflectionPrompt({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: programReflectionPromptsUrls,
      });
    },
    deleteReflectionPrompt({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: programReflectionPromptsUrls,
      });
    },
    // Scheduled Announcement Actions //
    async loadScheduledAnnouncementsList({ commit, rootState }) {
      const data = await getScheduledAnnouncements(rootState.programId);
      commit("setProgramScheduledAnnouncements", { data });
    },
  },
};
