<template>
  <div class="spire-tooltip">
    <spire-button
      aria-label="Tooltip"
      variant="tertiary"
      :icon-left="iconLeft"
      :title="title"
      :aria-describedby="tooltipId"
      class="no-underline p-0"
      :class="buttonClass"
      @focus="tooltipHidden = false"
      @blur="tooltipHidden = true"
      @mouseenter="tooltipHidden = false"
      @mouseleave="tooltipHidden = true"
    />
    <div
      :id="tooltipId"
      class="spire-tooltip__content"
      role="tooltip"
      :hidden="tooltipHidden"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { uniqueId } from "lodash";
export default {
  name: "SpireTooltip",
  props: {
    title: {
      type: String,
      default: "",
    },
    iconLeft: {
      type: String,
      default: "",
    },
    buttonClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tooltipHidden: true,
    };
  },
  computed: {
    tooltipId() {
      return uniqueId("spire-tooltip-");
    },
  },
};
</script>
