<template>
  <div class="selected-options-wrapper">
    <spire-empty-state
      v-if="!selectedOptions.length"
      :message="emptyStateMessage"
    />
    <ul v-else class="combobox-selected-options-list flex">
      <formulate-combobox-selected-option
        v-for="option in selectedOptions"
        :key="`selection-${option.value}`"
        :option="option"
        @delete-option="deleteOption"
      />
    </ul>
  </div>
</template>

<script>
import FormulateComboboxSelectedOption from "./FormulateComboboxSelectedOption.vue";
export default {
  props: {
    selectedOptions: {
      type: Array,
      default: () => [],
    },
    emptyStateMessage: {
      type: String,
      default: "No options selected yet!",
    },
  },
  emits: ["delete-option"],
  components: {
    FormulateComboboxSelectedOption,
  },
  methods: {
    deleteOption(value) {
      this.$emit("delete-option", value);
    },
  },
};
</script>
