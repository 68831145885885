import { SLIDE_TYPES } from "@/programs/utils/constants.js";

export const placeholderData = {
  welcome: `
  <h2>Welcome to IMMERSED</h2>
  <p>
  Our students are driven by a commitment to serve the common good through
  solutions that push the bounds of what is possible. Immersed, a collection of
  carefully designed experiential learning opportunities, serves to reinforce
  this commitment based on the belief that innovation is born out of exploration.
  </p>
  <p>
  Grounded on a bedrock of academic excellence, our immersive learning
  opportunities combine critical and creative thinking to provide meaningful
  environments for intellectual curiosity and daring innovation. Unique in our
  ability to reinforce core academic theory with calculated risk taking,
  extraordinary successes and noble failures, Immersed experiences provide a
  path for our students to become the engineers who can step into the future
  with the confidence, collaborative spirit, and socially conscious minds
  needed to change the world.
  </p>
  `,
  exercises: [
    {
      id: 1,
      maxSelections: 3,
      question: "What is most important to you?",
    },
    {
      id: 2,
      maxSelections: 3,
      question: "What is most important to your career?",
    },
    {
      id: 3,
      maxSelections: 3,
      question: "What do you most want to improve?",
    },
  ],
  programCompetencies: [
    {
      id: 1,
      name: "Teamwork",
      description: `
      Working to define and achieve a shared goal by leveraging
      individuals with different perspectives, roles, responsibilities,
      and aptitudes to overcome and use conflict to their advantage
      to create a more robust solution.`,
      competencies: [
        {
          id: 2,
          name: "Valuing the Development of Shared Rules, Norms, Structure",
          description: ``,
          levels: [
            {
              id: 1,
              name: "Exploring",
              help_text: `
              Students might engage at this level in a 100/200-level course or
              in the first semester of a co-curricular
              `,
              description: `
              The student values creating a useful idea or product in the ideation
              process but may struggle to produce ideas.
              `,
              order: 0,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
              ],
              assessments: [
                {
                  description: `The student discusses the usefulness of ideas as
                an important feature of the engineering design process.`,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              name: "Engaging",
              help_text: `
              Students might engage at this level in a 200/300-level course or
              in the first year of a co-curricular
              `,
              description: `
              The student creates one or more novel or unique ideas or products
              in the ideation process but doesn’t apply them to the problem solution.
              `,
              order: 1,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce useful ideas and
                any additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                The student discusses the usefulness of ideas as an important
                feature of the engineering design process and demonstrates
                knowledge of strategies to produce useful ideas in their own efforts.
                `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              name: "Synthesizing",
              help_text: `
              Students might engage at this level in a 300/400-level
              course or in extended co-curricular participation`,
              description: `
              The student extends a useful idea or product from the ideation
              process to create new knowledge or knowledge that crosses boundaries.
              `,
              order: 2,
              reflections: [
                `
                What makes a design idea novel? How might producing novel ideas
                connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce novel ideas and any
                additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                  The student illustrates the usefulness of ideas as an important feature
                  of the engineering design process and demonstrates an ability
                  to produce, assess, and/or apply useful ideas in their own efforts.
                  `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 3,
          name: "Recognition of and Commitment to a Common Purpose/Goal",
          description: ``,
          levels: [
            {
              id: 1,
              name: "Exploring",
              help_text: `
              Students might engage at this level in a 100/200-level course or
              in the first semester of a co-curricular
              `,
              description: `
              The student values creating a useful idea or product in the ideation
              process but may struggle to produce ideas.
              `,
              order: 0,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
              ],
              assessments: [
                {
                  description: `The student discusses the usefulness of ideas as
                an important feature of the engineering design process.`,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              name: "Engaging",
              help_text: `
              Students might engage at this level in a 200/300-level course or
              in the first year of a co-curricular
              `,
              description: `
              The student creates one or more novel or unique ideas or products
              in the ideation process but doesn’t apply them to the problem solution.
              `,
              order: 1,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce useful ideas and
                any additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                The student discusses the usefulness of ideas as an important
                feature of the engineering design process and demonstrates
                knowledge of strategies to produce useful ideas in their own efforts.
                `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              name: "Synthesizing",
              help_text: `
              Students might engage at this level in a 300/400-level
              course or in extended co-curricular participation`,
              description: `
              The student extends a useful idea or product from the ideation
              process to create new knowledge or knowledge that crosses boundaries.
              `,
              order: 2,
              reflections: [
                `
                What makes a design idea novel? How might producing novel ideas
                connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce novel ideas and any
                additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                  The student illustrates the usefulness of ideas as an important feature
                  of the engineering design process and demonstrates an ability
                  to produce, assess, and/or apply useful ideas in their own efforts.
                  `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 4,
          name: "Ability to Work Across Disciplinary Differences",
          description: ``,
          levels: [
            {
              id: 1,
              name: "Exploring",
              help_text: `
              Students might engage at this level in a 100/200-level course or
              in the first semester of a co-curricular
              `,
              description: `
              The student values creating a useful idea or product in the ideation
              process but may struggle to produce ideas.
              `,
              order: 0,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
              ],
              assessments: [
                {
                  description: `The student discusses the usefulness of ideas as
                an important feature of the engineering design process.`,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              name: "Engaging",
              help_text: `
              Students might engage at this level in a 200/300-level course or
              in the first year of a co-curricular
              `,
              description: `
              The student creates one or more novel or unique ideas or products
              in the ideation process but doesn’t apply them to the problem solution.
              `,
              order: 1,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce useful ideas and
                any additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                The student discusses the usefulness of ideas as an important
                feature of the engineering design process and demonstrates
                knowledge of strategies to produce useful ideas in their own efforts.
                `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              name: "Synthesizing",
              help_text: `
              Students might engage at this level in a 300/400-level
              course or in extended co-curricular participation`,
              description: `
              The student extends a useful idea or product from the ideation
              process to create new knowledge or knowledge that crosses boundaries.
              `,
              order: 2,
              reflections: [
                `
                What makes a design idea novel? How might producing novel ideas
                connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce novel ideas and any
                additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                  The student illustrates the usefulness of ideas as an important feature
                  of the engineering design process and demonstrates an ability
                  to produce, assess, and/or apply useful ideas in their own efforts.
                  `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 5,
      name: "Leadership",
      description: `
      Cultivating an environment that collectively develops a shared purpose
      and inspiring others to work toward it.`,
      competencies: [
        {
          id: 6,
          name: "Team Leadership",
          description: ``,
          levels: [
            {
              id: 1,
              name: "Exploring",
              help_text: `
              Students might engage at this level in a 100/200-level course or
              in the first semester of a co-curricular
              `,
              description: `
              The student values creating a useful idea or product in the ideation
              process but may struggle to produce ideas.
              `,
              order: 0,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
              ],
              assessments: [
                {
                  description: `The student discusses the usefulness of ideas as
                an important feature of the engineering design process.`,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              name: "Engaging",
              help_text: `
              Students might engage at this level in a 200/300-level course or
              in the first year of a co-curricular
              `,
              description: `
              The student creates one or more novel or unique ideas or products
              in the ideation process but doesn’t apply them to the problem solution.
              `,
              order: 1,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce useful ideas and
                any additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                The student discusses the usefulness of ideas as an important
                feature of the engineering design process and demonstrates
                knowledge of strategies to produce useful ideas in their own efforts.
                `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              name: "Synthesizing",
              help_text: `
              Students might engage at this level in a 300/400-level
              course or in extended co-curricular participation`,
              description: `
              The student extends a useful idea or product from the ideation
              process to create new knowledge or knowledge that crosses boundaries.
              `,
              order: 2,
              reflections: [
                `
                What makes a design idea novel? How might producing novel ideas
                connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce novel ideas and any
                additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                  The student illustrates the usefulness of ideas as an important feature
                  of the engineering design process and demonstrates an ability
                  to produce, assess, and/or apply useful ideas in their own efforts.
                  `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 7,
          name: "Organizational Leadership",
          description: ``,
          levels: [
            {
              id: 1,
              name: "Exploring",
              help_text: `
              Students might engage at this level in a 100/200-level course or
              in the first semester of a co-curricular
              `,
              description: `
              The student values creating a useful idea or product in the ideation
              process but may struggle to produce ideas.
              `,
              order: 0,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
              ],
              assessments: [
                {
                  description: `The student discusses the usefulness of ideas as
                an important feature of the engineering design process.`,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              name: "Engaging",
              help_text: `
              Students might engage at this level in a 200/300-level course or
              in the first year of a co-curricular
              `,
              description: `
              The student creates one or more novel or unique ideas or products
              in the ideation process but doesn’t apply them to the problem solution.
              `,
              order: 1,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce useful ideas and
                any additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                The student discusses the usefulness of ideas as an important
                feature of the engineering design process and demonstrates
                knowledge of strategies to produce useful ideas in their own efforts.
                `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              name: "Synthesizing",
              help_text: `
              Students might engage at this level in a 300/400-level
              course or in extended co-curricular participation`,
              description: `
              The student extends a useful idea or product from the ideation
              process to create new knowledge or knowledge that crosses boundaries.
              `,
              order: 2,
              reflections: [
                `
                What makes a design idea novel? How might producing novel ideas
                connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce novel ideas and any
                additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                  The student illustrates the usefulness of ideas as an important feature
                  of the engineering design process and demonstrates an ability
                  to produce, assess, and/or apply useful ideas in their own efforts.
                  `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 8,
          name: "Societal Leadership",
          description: ``,
          levels: [
            {
              id: 1,
              name: "Exploring",
              help_text: `
              Students might engage at this level in a 100/200-level course or
              in the first semester of a co-curricular
              `,
              description: `
              The student values creating a useful idea or product in the ideation
              process but may struggle to produce ideas.
              `,
              order: 0,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
              ],
              assessments: [
                {
                  description: `The student discusses the usefulness of ideas as
                an important feature of the engineering design process.`,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              name: "Engaging",
              help_text: `
              Students might engage at this level in a 200/300-level course or
              in the first year of a co-curricular
              `,
              description: `
              The student creates one or more novel or unique ideas or products
              in the ideation process but doesn’t apply them to the problem solution.
              `,
              order: 1,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce useful ideas and
                any additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                The student discusses the usefulness of ideas as an important
                feature of the engineering design process and demonstrates
                knowledge of strategies to produce useful ideas in their own efforts.
                `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              name: "Synthesizing",
              help_text: `
              Students might engage at this level in a 300/400-level
              course or in extended co-curricular participation`,
              description: `
              The student extends a useful idea or product from the ideation
              process to create new knowledge or knowledge that crosses boundaries.
              `,
              order: 2,
              reflections: [
                `
                What makes a design idea novel? How might producing novel ideas
                connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce novel ideas and any
                additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                  The student illustrates the usefulness of ideas as an important feature
                  of the engineering design process and demonstrates an ability
                  to produce, assess, and/or apply useful ideas in their own efforts.
                  `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 9,
      name: "Creativity",
      description: `
      Ability to generate ideas, processes, products that are both novel
      (unique, original, atypical, cutting-edge) and appropriate
      (relevant, practical, useful, applicable, fitting, effective).`,
      competencies: [
        {
          id: 10,
          name: "Production of Novel Ideas",
          description: ``,
          levels: [
            {
              id: 1,
              name: "Exploring",
              help_text: `
              Students might engage at this level in a 100/200-level course or
              in the first semester of a co-curricular
              `,
              description: `
              The student values creating a novel or unique idea or product
              in the ideation process but may struggle to produce novel ideas.
              `,
              order: 0,
              reflections: [
                `
                What makes a design idea novel? How might producing novel ideas
                connect to your work as a professional engineer?
                `,
              ],
              assessments: [
                {
                  description: `The student discusses novel ideas as an important feature of
                the engineering design process. `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              name: "Engaging",
              help_text: `
              Students might engage at this level in a 200/300-level course or
              in the first year of a co-curricular
              `,
              description: `
              The student creates one or more novel or unique ideas or products
              in the ideation process but doesn’t apply them to the problem solution.
              `,
              order: 1,
              reflections: [
                `
                What makes a design idea novel? How might producing novel ideas
                connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce novel ideas and any
                additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                The student discusses novel ideas as an important feature of
                the engineering design process and demonstrates knowledge of
                strategies to produce novel ideas in their own efforts.
                `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              name: "Synthesizing",
              help_text: `
              Students might engage at this level in a 300/400-level
              course or in extended co-curricular participation`,
              description: `
              The student extends a novel or unique idea or product from the
              ideation process to create new knowledge or knowledge that crosses boundaries.
              `,
              order: 2,
              reflections: [
                `
                What makes a design idea novel? How might producing novel ideas
                connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce novel ideas and any
                additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                  The student discusses novel ideas as an important feature of
                  the engineering design process and demonstrates an ability to
                  produce,
                  `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 11,
          name: "Production of Useful Ideas",
          description: ``,
          levels: [
            {
              id: 1,
              name: "Exploring",
              help_text: `
              Students might engage at this level in a 100/200-level course or
              in the first semester of a co-curricular
              `,
              description: `
              The student values creating a useful idea or product in the ideation
              process but may struggle to produce ideas.
              `,
              order: 0,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
              ],
              assessments: [
                {
                  description: `The student discusses the usefulness of ideas as
                an important feature of the engineering design process.`,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              name: "Engaging",
              help_text: `
              Students might engage at this level in a 200/300-level course or
              in the first year of a co-curricular
              `,
              description: `
              The student creates one or more novel or unique ideas or products
              in the ideation process but doesn’t apply them to the problem solution.
              `,
              order: 1,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce useful ideas and
                any additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                The student discusses the usefulness of ideas as an important
                feature of the engineering design process and demonstrates
                knowledge of strategies to produce useful ideas in their own efforts.
                `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              name: "Synthesizing",
              help_text: `
              Students might engage at this level in a 300/400-level
              course or in extended co-curricular participation`,
              description: `
              The student extends a useful idea or product from the ideation
              process to create new knowledge or knowledge that crosses boundaries.
              `,
              order: 2,
              reflections: [
                `
                What makes a design idea novel? How might producing novel ideas
                connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce novel ideas and any
                additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                  The student illustrates the usefulness of ideas as an important feature
                  of the engineering design process and demonstrates an ability
                  to produce, assess, and/or apply useful ideas in their own efforts.
                  `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 12,
          name: "Applying Divergent and Convergent Thinking Processes",
          description: ``,
          levels: [
            {
              id: 1,
              name: "Exploring",
              help_text: `
              Students might engage at this level in a 100/200-level course or
              in the first semester of a co-curricular
              `,
              description: `
              The student values creating a useful idea or product in the ideation
              process but may struggle to produce ideas.
              `,
              order: 0,
              reflections: [
                `
                What do you think your instructor wanted you to learn about creative processes?
                `,
                `
                How do you identify different thinking strategies when trying to solve a problem creatively?
                `,
              ],
              assessments: [
                {
                  description: `The student discusses what they’ve learned about
                the creative process in terms of multiple ways of thinking.`,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              name: "Engaging",
              help_text: `
              Students might engage at this level in a 200/300-level course or
              in the first year of a co-curricular
              `,
              description: `
              The student creates one or more novel or unique ideas or products
              in the ideation process but doesn’t apply them to the problem solution.
              `,
              order: 1,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
                `
                List the three projects /activities /lectures /assignments, etc.
                that you think most impacted your creative process skill
                development. Please also explain why and how they affected your development.
                `,
              ],
              assessments: [
                {
                  description: `
                The student demonstrates what they’ve learned about the creative
                process by discussing what they have learned about strategies
                for divergent and convergent thinking.
                `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              name: "Synthesizing",
              help_text: `
              Students might engage at this level in a 300/400-level
              course or in extended co-curricular participation`,
              description: `
              The student extends a useful idea or product from the ideation
              process to create new knowledge or knowledge that crosses boundaries.
              `,
              order: 2,
              reflections: [
                `
                What makes a design idea novel? How might producing novel ideas
                connect to your work as a professional engineer?
                `,
                `
                What do you think helped most in developing your creative process?
                Please explain your process for solving a problem creatively.
                `,
              ],
              assessments: [
                {
                  description: `
                  The student demonstrates what they’ve learned about the
                  creative process by discussing how they use strategies for
                  divergent and convergent thinking in their own creative process.
                  `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 13,
          name: "Innovation",
          description: ``,
          levels: [
            {
              id: 1,
              name: "Exploring",
              help_text: `
              Students might engage at this level in a 100/200-level course or
              in the first semester of a co-curricular
              `,
              description: `
              The student values creating a useful idea or product in the ideation
              process but may struggle to produce ideas.
              `,
              order: 0,
              reflections: [
                `
                How do you determine if an idea for your design is novel?
                `,
                `
                How do you determine if an idea for your design is useful?
                `,
              ],
              assessments: [
                {
                  description: `The student recognizes features of a
                bigger idea as novel or useful.
                `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              name: "Engaging",
              help_text: `
              Students might engage at this level in a 200/300-level course or
              in the first year of a co-curricular
              `,
              description: `
              The student creates one or more novel or unique ideas or products
              in the ideation process but doesn’t apply them to the problem solution.
              `,
              order: 1,
              reflections: [
                `
                What makes a design idea useful in engineering? How might
                producing useful ideas connect to your work as a professional engineer?
                `,
                `
                Reflect on how you view yourself as a creative designer.
                Include comments on your ability to produce useful ideas and
                any additional skills you would like to develop.
                `,
              ],
              assessments: [
                {
                  description: `
                The student recognizes ways to combine novel/useful features of
                multiple ideas to create a new idea that is more as novel or useful.
                `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              name: "Synthesizing",
              help_text: `
              Students might engage at this level in a 300/400-level
              course or in extended co-curricular participation`,
              description: `
              The student extends a useful idea or product from the ideation
              process to create new knowledge or knowledge that crosses boundaries.
              `,
              order: 2,
              reflections: [
                `
                In your future career, how might you approach combining ideas
                that are novel and useful to create an innovative design solution?
                `,
              ],
              assessments: [
                {
                  description: `
                  The student recognizes ways to combine novel/useful features
                  of multiple ideas into entirely new forms with an emphasis
                  on the desire to solve a problem.
                  `,
                  options: [
                    {
                      text: "Yes",
                      success: true,
                    },
                    {
                      text: "No",
                      success: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  programOpportunities: [
    {
      id: 1,
      name: "Internship",
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Curabitur non dolor sit amet enim pharetra euismod. Vivamus elit mi,
      convallis at augue vitae, scelerisque consequat tellus. Curabitur eget
      orci sed nunc efficitur consectetur. Aenean risus mi, dictum malesuada
      augue ac, cursus viverra lectus. Donec blandit ipsum massa, a viverra sem
      congue id. Phasellus eget lorem varius est congue egestas. Morbi felis
      dui, euismod et semper ut, vulputate ac mi.`,
      competencies: [9, 5, 1],
    },
    {
      id: 2,
      name: "Study Abroad",
      description: `Ut malesuada pretium rhoncus. Sed ante dolor, convallis ac orci quis,
      ullamcorper sollicitudin arcu. Aliquam volutpat auctor scelerisque.
      Praesent suscipit ac lorem eu varius. Aliquam erat volutpat. Quisque nibh
      augue, scelerisque vitae mollis eget, eleifend eu est. Vestibulum at porta
      dolor, eget auctor risus. Integer et quam libero. Nullam tincidunt justo
      nunc, in interdum tortor tristique at. Donec molestie sapien leo, at auctor
      libero condimentum vel. Nulla euismod tortor sem, quis facilisis urna accumsan
      sit amet. In blandit, lacus vitae scelerisque dictum, augue ante tincidunt
      sem, a blandit sem orci placerat felis. Etiam id urna dolor. Donec varius
      vel lorem at tristique. Donec eget tincidunt turpis.`,
      competencies: [9],
    },
    {
      id: 3,
      name: "Seminar",
      description: `Fusce scelerisque, nisi non tempor mattis, odio neque dignissim
      lorem, id posuere nunc orci ac sapien. Vivamus viverra rutrum orci nec
      iaculis. Etiam nunc nisl, semper ut ipsum sed, vehicula mollis mauris.
      Quisque blandit nunc nec placerat volutpat. Duis vulputate magna lectus,
      sed vehicula lectus feugiat id. Vestibulum congue lorem enim, id
      scelerisque erat eleifend et. Pellentesque in nulla risus. Maecenas porta
      pulvinar venenatis. Duis sed lacus pulvinar, malesuada mi a, fermentum magna.
      Sed feugiat, elit a interdum mollis, est ex efficitur urna, et pulvinar
      purus lectus ultrices nisi. Nullam egestas vel justo id tristique.`,
      competencies: [9, 5],
    },
    {
      id: 4,
      name: "Workshop",
      description: `Maecenas sed massa eget nisl condimentum porttitor.
      Pellentesque euismod tempor tellus in pretium. Donec tristique, diam sed
      condimentum porttitor, turpis ante fermentum odio, vel ornare nisl risus
      a dolor. Nullam id leo vulputate, sollicitudin dolor eget, fermentum ligula.
      Duis imperdiet cursus turpis. Nullam lobortis convallis tellus, et lacinia
      libero auctor viverra. Nam vehicula quam eget aliquet vestibulum. Fusce
      erat ante, interdum at dui sed, facilisis blandit nibh. Donec ornare eu
      erat sed lobortis. Aliquam dignissim maximus nulla, at condimentum purus
      scelerisque eget. Cras at faucibus justo. Mauris id nisl vestibulum,
      consequat diam et, iaculis lacus. Donec rutrum vitae dui sed efficitur.`,
      competencies: [9, 5],
    },
    {
      id: 5,
      name: "Student Organization",
      description: `Nunc tempus faucibus urna, vel blandit felis feugiat at.
      Nulla erat nisi, congue eget vulputate non, porta et tortor. Praesent
      dapibus sit amet enim vitae auctor. Nam scelerisque eleifend cursus.
      Phasellus tincidunt, nibh vitae dapibus posuere, lectus nisi iaculis ex,
      nec venenatis velit orci at erat. Sed a rhoncus massa. Integer molestie ut
      ipsum vitae accumsan. Vivamus condimentum rutrum congue. Ut elementum
      molestie finibus. Quisque in lorem lectus.`,
      competencies: [5, 1],
    },
    {
      id: 6,
      name: "Robotics Club",
      description: `Nunc tempus faucibus urna, vel blandit felis feugiat at.
      Nulla erat nisi, congue eget vulputate non, porta et tortor. Praesent
      dapibus sit amet enim vitae auctor. Nam scelerisque eleifend cursus.
      Phasellus tincidunt, nibh vitae dapibus posuere, lectus nisi iaculis ex,
      nec venenatis velit orci at erat. Sed a rhoncus massa. Integer molestie ut
      ipsum vitae accumsan. Vivamus condimentum rutrum congue. Ut elementum
      molestie finibus. Quisque in lorem lectus.`,
      competencies: [9, 5, 1],
    },
    {
      id: 7,
      name: "Solar Car Team",
      description: `Nunc tempus faucibus urna, vel blandit felis feugiat at.
      Nulla erat nisi, congue eget vulputate non, porta et tortor. Praesent
      dapibus sit amet enim vitae auctor. Nam scelerisque eleifend cursus.
      Phasellus tincidunt, nibh vitae dapibus posuere, lectus nisi iaculis ex,
      nec venenatis velit orci at erat. Sed a rhoncus massa. Integer molestie ut
      ipsum vitae accumsan. Vivamus condimentum rutrum congue. Ut elementum
      molestie finibus. Quisque in lorem lectus.`,
      competencies: [9, 5, 1],
    },
  ],
  user_levels: [
    {
      competency_id: 10,
      level_id: 1,
    },
    {
      competency_id: 13,
      level_id: 2,
    },
  ],
  onboardingSlides: [
    {
      name: "Welcome to Spire",
      order: 0,
      slideType: SLIDE_TYPES.default,
      topText: "<h2>Welcome to <span>Spire</span></h2>",
      bottomText: "",
    },
    {
      name: "Your Career Journey",
      order: 1,
      slideType: SLIDE_TYPES.default,
      topText:
        "<h2>Spire will support you on your <span>career journey</span></h2>",
      bottomText:
        "<p>We'll help you plan, track, and make sense of your learning outside the classroom, so that you can:</p><ul><li><p>write about yourself clearly on a resume or cover letter</p></li><li><p>talk about yourself clearly in interviews</p></li></ul><p>Here's what you can expect...</p>",
    },
    {
      name: "First, you'll take a survey",
      order: 2,
      slideType: SLIDE_TYPES.default,
      topText: "<h2>First, you'll take a <span>survey</span></h2>",
      bottomText:
        "<p>Help us get to know you so we can personalize Spire just for you.</p><p>You'll retake the survey once a year so we can keep up with your learning.</p>",
    },
    {
      name: "Second, you'll choose competencies to focus on",
      order: 3,
      slideType: SLIDE_TYPES.default,
      topText:
        "<h2>Second, you'll choose <span>competencies</span> to focus on</h2>",
      bottomText:
        "<p>By competencies, we mean things like teamwork, leadership, and communication — qualities that relate to <strong>how people work</strong>, rather than what they know.</p>",
    },
    {
      name: "Dimensions",
      order: 4,
      slideType: SLIDE_TYPES.default,
      topText:
        '<h2>Your program has broken competencies into subcategories called <span>"dimensions"</span></h2>',
      bottomText:
        "<p>For example, communication might be broken into verbal communication and written communication.</p>",
    },
    {
      name: "Third, explore opportunities",
      order: 5,
      slideType: SLIDE_TYPES.default,
      topText:
        "<h2>Third, you'll explore <span>opportunities</span> to build your competencies</h2>",
      bottomText:
        "<p>Explore opportunities like speakers, workshops, study abroad, internships, and even courses.</p><p>Opportunities are connected to competencies so you can find what's most relevant to you.</p>",
    },
    {
      name: "Later, you'll reflect",
      order: 6,
      slideType: SLIDE_TYPES.default,
      topText:
        "<h2>Later... after opportunities, you'll <span>reflect</span></h2>",
      bottomText:
        "<p>When you complete an opportunity, we'll prompt you to add a few details to help you remember.</p><p><strong>Reflections will help you write the bullet points on your resume later.</strong></p>",
    },
    {
      name: "And when you're ready, you'll level-up",
      order: 7,
      slideType: SLIDE_TYPES.default,
      topText: "<h2>And when you're ready, you'll <span>level-up</span></h2>",
      bottomText:
        "<p>To move up an expertise level, you'll synthesize what you've learned within a competency or dimension.</p><p><strong>Demonstrating your learning in this way is great practice for answering interview questions.</strong></p>",
    },
    {
      name: "More",
      order: 8,
      slideType: SLIDE_TYPES.default,
      topText: "<h2>Sometimes level-ups are <span>automatic</span></h2>",
      bottomText:
        "<p>For example, if you get a passing grade on a course.</p><p>Other times, peers or someone on staff will review your level-up request.</p><p>We'll always alert you if other people will view your level-up requests.</p>",
    },
  ],
};
