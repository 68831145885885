import { apiClient } from "@/programs/services/ApiClient.js";
import {
  programCompetencyActivitiesUrl,
  v2AdminProgramCompetencyCopyUrl,
  v2AdminProgramCompetencyDetailUrl,
  v2AdminProgramCompetencyListUrl,
  v2AdminProgramDimensionCopyUrl,
  v2AdminProgramDimensionDetailUrl,
  v2AdminProgramDimensionListUrl,
  v2AdminProgramLevelDetailUrl,
  v2AdminProgramLevelListUrl,
  v2AdminProgramLevelOptionListUrl,
  v2AdminProgramReflectionPromptDetailUrl,
  v2AdminProgramReflectionPromptListUrl,
  COMPETENCIES_API_V2_URLS,
} from "@/programs/urls.js";

export const createCompetency = async (programId, competencyData) => {
  const url = v2AdminProgramCompetencyListUrl.stringify({ programId });
  const { data, status, errors } = await apiClient
    .post(url, competencyData)
    .catch((error) => {
      return {
        data: error.response.data.instance,
        errors: error.response.data.errors,
        status: error.response.status,
      };
    });
  return { data, status, errors };
};

export const copyCompetency = async (programId, competencyId) => {
  const url = v2AdminProgramCompetencyCopyUrl.stringify({
    programId,
    competencyId,
  });
  const { data, status, errors } = await apiClient.post(url).catch((error) => {
    return {
      data: error.response.data.instance,
      errors: error.response.data.errors,
      status: error.response.status,
    };
  });
  return { data, status, errors };
};

export const createDimension = async (programId, dimensionData) => {
  const url = v2AdminProgramDimensionListUrl.stringify({ programId });
  const { data, status, errors } = await apiClient
    .post(url, dimensionData)
    .catch((error) => {
      return {
        data: error.response.data.instance,
        errors: error.response.data.errors,
        status: error.response.status,
      };
    });
  return { data, status, errors };
};

export const copyDimension = async (programId, dimensionId) => {
  const url = v2AdminProgramDimensionCopyUrl.stringify({
    programId,
    dimensionId,
  });
  const { data, status, errors } = await apiClient.post(url).catch((error) => {
    return {
      data: error.response.data.instance,
      errors: error.response.data.errors,
      status: error.response.status,
    };
  });
  return { data, status, errors };
};

export const createLevel = async (programId, levelData) => {
  const url = v2AdminProgramLevelListUrl.stringify({ programId });
  const { data, status, errors } = await apiClient
    .post(url, levelData)
    .catch((error) => {
      return {
        data: error.response.data.instance,
        errors: error.response.data.errors,
        status: error.response.status,
      };
    });
  return { data, status, errors };
};

export const createPrompt = async (programId, promptData) => {
  const url = v2AdminProgramReflectionPromptListUrl.stringify({ programId });
  const { data, status, errors } = await apiClient
    .post(url, promptData)
    .catch((error) => {
      return {
        data: error.response.data.instance,
        errors: error.response.data.errors,
        status: error.response.status,
      };
    });
  return { data, status, errors };
};

export const deleteCompetency = async (programId, competencyId) => {
  const url = v2AdminProgramCompetencyDetailUrl.stringify({
    programId,
    competencyId,
  });
  const { data, status, errors, message } = await apiClient
    .delete(url)
    .catch((error) => {
      return {
        data: error.response.data.instance,
        errors: error.response.data.errors,
        status: error.response.status,
        message: error.response.data.message,
      };
    });
  return { data, status, errors, message };
};

export const deleteDimension = async (programId, dimensionId) => {
  const url = v2AdminProgramDimensionDetailUrl.stringify({
    programId,
    dimensionId,
  });
  const { data, status, errors, message } = await apiClient
    .delete(url)
    .catch((error) => {
      return {
        data: error.response.data.instance,
        errors: error.response.data.errors,
        status: error.response.status,
        message: error.response.data.message,
      };
    });
  return { data, status, errors, message };
};

export const deleteLevel = async (programId, levelId) => {
  const url = v2AdminProgramLevelDetailUrl.stringify({ programId, levelId });
  const { data, status, errors, message } = await apiClient
    .delete(url)
    .catch((error) => {
      return {
        data: error.response.data.instance,
        errors: error.response.data.errors,
        status: error.response.status,
        message: error.response.data.message,
      };
    });
  return { data, status, errors, message };
};

export const deletePrompt = async (programId, promptId) => {
  const url = v2AdminProgramReflectionPromptDetailUrl.stringify({
    programId,
    promptId,
  });
  const { data, status, errors, message } = await apiClient
    .delete(url)
    .catch((error) => {
      return {
        data: error.response.data.instance,
        errors: error.response.data.errors,
        status: error.response.status,
        message: error.response.data.message,
      };
    });
  return { data, status, errors, message };
};

export const getCompetencies = async (programId) => {
  const url = v2AdminProgramCompetencyListUrl.stringify({ programId });
  const { data } = await apiClient.get(url);
  return data;
};

export const getDimensionAdvancement = async (programId, competencyId) => {
  const url = COMPETENCIES_API_V2_URLS.dimensionAdvancement.stringify({
    programId,
    competencyId,
  });
  const { data } = await apiClient.get(url);
  return data;
};

export const updateDimension = async (
  programId,
  dimensionId,
  dimensionData,
) => {
  const url = v2AdminProgramDimensionDetailUrl.stringify({
    programId,
    dimensionId,
  });
  const { data, status, errors } = await apiClient
    .put(url, dimensionData)
    .catch((error) => {
      return {
        data: error.response.data.instance,
        errors: error.response.data.errors,
        status: error.response.status,
      };
    });
  return { data, status, errors };
};

export const updateLevel = async (programId, levelId, levelData) => {
  const url = v2AdminProgramLevelDetailUrl.stringify({ programId, levelId });
  const { data, status, errors } = await apiClient
    .put(url, levelData)
    .catch((error) => {
      return {
        data: error.response.data.instance,
        errors: error.response.data.errors,
        status: error.response.status,
      };
    });
  return { data, status, errors };
};

export const updatePrompt = async (programId, promptId, promptData) => {
  const url = v2AdminProgramReflectionPromptDetailUrl.stringify({
    programId,
    promptId,
  });
  const { data, status, errors } = await apiClient
    .put(url, promptData)
    .catch((error) => {
      return {
        data: error.response.data.instance,
        errors: error.response.data.errors,
        status: error.response.status,
      };
    });
  return { data, status, errors };
};

export const getCompetencyDetail = async (programId, competencyId) => {
  const url = v2AdminProgramCompetencyDetailUrl.stringify({
    programId,
    competencyId,
  });
  const { data } = await apiClient.get(url);
  return data;
};

export const getDimensionDetail = async (programId, dimensionId) => {
  const url = v2AdminProgramDimensionDetailUrl.stringify({
    programId,
    dimensionId,
  });
  const { data } = await apiClient.get(url);
  return data;
};

export const getLevelOptions = async ({ programId, params }) => {
  const url = v2AdminProgramLevelOptionListUrl.stringify({ programId });
  const { data } = await apiClient.get(url, { params });
  return data;
};

export const getLevelDetail = async (programId, levelId) => {
  const url = v2AdminProgramLevelDetailUrl.stringify({ programId, levelId });
  const { data } = await apiClient.get(url);
  return data;
};

export const getCompetencyActivities = async (
  programId,
  competencyId,
  page = 1,
) => {
  const params = { page };
  const url = programCompetencyActivitiesUrl.stringify({
    programId,
    competencyId,
  });
  const { data } = await apiClient.get(url, { params });
  return data;
};
