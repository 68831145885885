import { NAME_ASCENDING, SORT_FUNCTIONS } from "../utils/sort.js";

function filterBySearchText(object) {
  let opportunity = object.assignment || object;
  let name = opportunity.name.toLowerCase();
  let searchText = opportunityToolbarModule.state.searchText.toLowerCase();
  return name.includes(searchText);
}

export const opportunityToolbarModule = {
  namespaced: true,
  state: {
    searchText: "",
    sort: NAME_ASCENDING,
    selectedCompetencyFilters: [],
    selectedCategoryFilters: [],
    selectedDurationFilters: [],
    selectedIntensityFilters: [],
  },
  getters: {
    selectedCompetencyFilterIds(state) {
      return state.selectedCompetencyFilters.map((f) => f.value);
    },
    selectedCategoryFilterIds(state) {
      return state.selectedCategoryFilters.map((f) => f.value);
    },
    selectedDurationFilterValues(state) {
      return state.selectedDurationFilters.map((f) => f.value);
    },
    selectedIntensityFilterIds(state) {
      return state.selectedIntensityFilters.map((f) => f.value);
    },
    selectedDurationFilterIds(state) {
      return state.selectedDurationFilters.map((f) => f.value);
    },
    filteredActivities: (state, getters) => (activities) => {
      if (state.searchText.length) {
        activities = activities.filter(filterBySearchText);
      }
      if (state.selectedCompetencyFilters.length) {
        let selectedCompetencyIds = getters.selectedCompetencyFilterIds;
        activities = activities.filter((activity) => {
          let opportunity = activity.assignment;
          return opportunity.learning_objective_ids.some((competencyId) => {
            return selectedCompetencyIds.includes(competencyId);
          });
        });
      }
      if (state.selectedCategoryFilters.length) {
        let selectedCategoryIds = getters.selectedCategoryFilterIds;
        activities = activities.filter((activity) => {
          let opportunity = activity.assignment;
          return selectedCategoryIds.includes(opportunity.assignment_type_id);
        });
      }
      if (state.selectedDurationFilters.length) {
        let selectedDurationValues = getters.selectedDurationFilterValues;
        activities = activities.filter((activity) => {
          let opportunity = activity.assignment;
          let details = opportunity.assignment_detail || {};
          return selectedDurationValues.includes(details.duration);
        });
      }
      if (state.selectedIntensityFilters.length) {
        let selectedIntensityIds = getters.selectedIntensityFilterIds;
        activities = activities.filter((activity) => {
          let opportunity = activity.assignment;
          let details = opportunity.assignment_detail || {};
          return selectedIntensityIds.includes(details.effort_level_id);
        });
      }
      return activities;
    },
    filteredAndSortedActivities:
      (state, getters) => (activitiesWithOpportunities) => {
        let activities = [...activitiesWithOpportunities];
        let sortMethodName = state.sort;
        let sortMethod = SORT_FUNCTIONS[sortMethodName];
        return getters.filteredActivities(activities).sort(sortMethod);
      },
    filteredOpportunities: (state, getters) => (opportunities) => {
      if (state.searchText.length) {
        opportunities = opportunities.filter(filterBySearchText);
      }
      if (state.selectedCompetencyFilters.length) {
        let selectedCompetencyIds = getters.selectedCompetencyFilterIds;
        opportunities = opportunities.filter((opportunity) => {
          return opportunity.learning_objective_ids.some((competencyId) => {
            return selectedCompetencyIds.includes(competencyId);
          });
        });
      }
      if (state.selectedCategoryFilters.length) {
        let selectedCategoryIds = getters.selectedCategoryFilterIds;
        opportunities = opportunities.filter((opportunity) => {
          return selectedCategoryIds.includes(opportunity.assignment_type_id);
        });
      }
      if (state.selectedDurationFilters.length) {
        let selectedDurationValues = getters.selectedDurationFilterValues;
        opportunities = opportunities.filter((opportunity) => {
          let details = opportunity.assignment_detail || {};
          return selectedDurationValues.includes(details.duration);
        });
      }
      if (state.selectedIntensityFilters.length) {
        let selectedIntensityIds = getters.selectedIntensityFilterIds;
        opportunities = opportunities.filter((opportunity) => {
          let details = opportunity.assignment_detail || {};
          return selectedIntensityIds.includes(details.effort_level_id);
        });
      }
      return opportunities;
    },
    filteredAndSortedOpportunities: (state, getters) => (opportunities) => {
      let opportunitiesCopy = [...opportunities];
      let sortMethodName = state.sort;
      let sortMethod = SORT_FUNCTIONS[sortMethodName];
      return getters.filteredOpportunities(opportunitiesCopy).sort(sortMethod);
    },
  },
  mutations: {
    updateSearchText(state, searchText) {
      state.searchText = searchText;
    },
    updateSelectedCompetencyFilters(state, selectedCompetencyFilters) {
      state.selectedCompetencyFilters = selectedCompetencyFilters;
    },
    updateSelectedCategoryFilters(state, selectedCategoryFilters) {
      state.selectedCategoryFilters = selectedCategoryFilters;
    },
    updateSelectedDurationFilters(state, selectedDurationFilters) {
      state.selectedDurationFilters = selectedDurationFilters;
    },
    updateSelectedIntensityFilters(state, selectedIntensityFilters) {
      state.selectedIntensityFilters = selectedIntensityFilters;
    },
    updateSort(state, sort) {
      state.sort = sort;
    },
  },
};
