<script setup>
import { computed } from "vue";
import VueMultiselect from "vue-multiselect";
import { useVMSFormKitShim } from "@/programs/composables/useVMSFormKitShim.js";

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const options = computed(() => props.context.attrs.options);
const multiple = props.context.multiple;
const loading = props.context.loading;
const closeOnSelect = props.context.closeOnSelect || multiple === false;
const searchable = props.context.searchable;
const placeholder = props.context.placeholder;
const allowEmpty = props.context.allowEmpty;
const groupValues = props.context.groupValues || "";
const groupLabel = props.context.groupLabel || "";

const { value } = useVMSFormKitShim(props.context, options);
</script>

<template>
  <vue-multiselect
    v-model="value"
    :show-labels="true"
    :multiple="multiple"
    :searchable="searchable"
    :close-on-select="closeOnSelect"
    :options="options"
    :disabled="context.disabled"
    :group-values="groupValues"
    :group-label="groupLabel"
    :loading="loading"
    :name="context.node.name"
    :id="context.id"
    :placeholder="placeholder"
    :allow-empty="allowEmpty"
    label="label"
    track-by="value"
    open-direction="auto"
    v-bind="$attrs"
    class="vue-multiselect"
  >
    <template #tag="{ option }">
      <span class="multiselect__tag">
        {{ option.label }}
      </span>
    </template>
  </vue-multiselect>
</template>
