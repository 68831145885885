<template>
  <div :data-type="context.type" :class="context.type">
    <flat-pickr
      v-bind="context.attrs"
      :config="flatpickrDateTimeConfig"
      v-model="value"
      class="flatpickr"
      @on-open="handleOpen"
      ref="datepicker"
    />
  </div>
</template>

<script>
export default {
  name: "FormKitDatePicker",
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      flatpickrDateTimeConfig: {
        altInput: true,
        inline: this.context.inline || false,
        enableTime: this.context.mode === "dateTime",
        dateFormat: "Z", // ISO Date format 2017-03-04T01:23:43.000Z
        defaultDate: null, // set default date in parent so FormKit knows about it
        defaultHour: 0,
        defaultMinute: 0,
        minuteIncrement: 1,
      },
    };
  },
  computed: {
    value: {
      get() {
        return this.context._value || null;
      },
      set(value) {
        this.context.node.input(value);
      },
    },
  },
  methods: {
    handleOpen() {
      const datepickerInputEl = this.$refs.datepicker.$el.nextElementSibling;
      datepickerInputEl.setAttribute(
        "aria-description",
        "Press the down arrow key to enter the calendar, then use the left, right, up, or down arrow keys to move between dates. To move by month, press control + option + left or right arrow keys. To move by year, press control + option + up or down arrow keys. To select a date, press enter.",
      );
    },
  },
};
</script>
