<template>
  <div v-click-outside="hideMenu" class="spire-action-menu">
    <spire-button
      @click="toggleShowMenu"
      icon-left="fa-gear"
      :icon-right="iconRight"
      variant="secondary"
      :aria-label="triggerAriaLabel"
      class="m-0"
    />
    <ul v-show="showMenu" class="spire-action-menu__list">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SpireActionMenu",
  props: {
    targetName: {
      type: String,
      default: "Object",
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    iconRight() {
      return this.showMenu ? "fa-caret-up" : "fa-caret-down";
    },
    triggerAriaLabel() {
      const verb = this.showMenu ? "Hide" : "Show";
      return `${verb} ${this.targetName} actions`;
    },
  },
  methods: {
    toggleShowMenu() {
      this.showMenu = !this.showMenu;
      let main = document.querySelector("main");
      if (this.showMenu && main) {
        main.classList.add("pb-12");
      } else if (main) {
        main.classList.remove("pb-12");
      }
    },
    hideMenu() {
      this.showMenu = false;
    },
  },
};
</script>
