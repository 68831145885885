import { DateTime } from "luxon";

// DEPRECATED: Use utils/dates.js instead

export const formatDatesMixin = {
  methods: {
    shortDate: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString(DateTime.DATE_SHORT);
    },
    shortDateTime: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString(DateTime.DATETIME_SHORT);
    },
    monthYear: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString({ month: "long", year: "numeric" });
    },
    fullDate: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString(DateTime.DATE_FULL);
    },
    time: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString(DateTime.TIME_SIMPLE);
    },
    dateAtTime: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toFormat("cccc, LLLL d y @ t");
    },
    mediumDate: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString(DateTime.DATE_MED);
    },
    mediumDateTime: (isoTimestamp) => {
      const dt = DateTime.fromISO(isoTimestamp);
      return dt.toLocaleString(DateTime.DATETIME_MED);
    },
  },
};
