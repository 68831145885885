<script setup lang="ts">
import { computed } from "vue";
import { range, clamp } from "lodash";
import { API } from "@/programs/types.ts";

const RADIUS = 3;

interface Props {
  meta: API.PaginationMetadata;
}

const props = defineProps<Props>();
defineEmits(["page"]);
const generatePageNumbers = (
  current: number,
  total: number,
  radius: number = RADIUS,
) => {
  if (total < 2 * radius) return range(1, total + 1);
  const midpoint = clamp(current, radius, total - radius);
  const rangeStart = midpoint - radius <= 1 ? 1 : midpoint - radius;
  const rangeEnd = midpoint + radius + 1;
  return range(rangeStart, rangeEnd);
};

const pageNumbers = computed(() =>
  generatePageNumbers(props.meta.page, props.meta.pages),
);
</script>
<template>
  <div class="table-design-pagination">
    <div class="paginate-nav">
      <button
        :disabled="!meta.prev"
        @click="$emit('page', meta.prev)"
        class="paginate-nav__page-button no-underline"
        aria-label="Previous Page"
      >
        <i class="fa fa-caret-left fa-lg"></i>
      </button>
      <button
        v-for="n in pageNumbers"
        :key="n"
        :class="{ active: n === meta.page }"
        :disabled="n === meta.page"
        class="paginate-nav__number-button"
        type="button"
        @click="$emit('page', n)"
      >
        {{ n }}
      </button>
      <button
        :disabled="!meta.next"
        type="button"
        @click="$emit('page', meta.next)"
        class="paginate-nav__page-button no-underline"
        aria-label="Next Page"
      >
        <i class="fa fa-caret-right fa-lg"></i>
      </button>
    </div>
    <p aria-live="polite">
      <em>
        Viewing {{ meta.from }} - {{ meta.to }} of
        {{ meta.count }}
      </em>
    </p>
  </div>
</template>
