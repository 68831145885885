import axios from "axios";

import { isEmpty } from "lodash";

import {
  userDataUrl,
  programUserDataUrl,
  userRankingUrls,
  userCompetencyUrls,
  userLevelRequestUrls,
  userOpportunityUrls,
  userActivityUrls,
  userReflectionUrls,
  userReflectionAttachFilesUrl,
  userReflectionDetachFileUrl,
  userMembershipCohortsListUrl,
  userLeadershipCohortsListUrl,
  programOnboardingCompletedUrl,
  userRequiredCompetencyAssessmentsUrl,
  userProgressListUrl,
  userMicroprogressListUrl,
  programMemberUrls,
} from "../urls.js";

import { LEVEL_REQUEST_STATUS, NOT_STARTED_YET } from "../utils/constants.js";

import { genericMixin } from "./generic.js";

export const userModule = {
  namespaced: true,
  state: () => ({
    programUserData: {},
    userData: {},
    userCompetencies: [],
    userExercises: [],
    userActivities: [],
    userOpportunities: [],
    userReflections: [],
    userMembershipCohorts: [],
    userLeadershipCohorts: [],
    userLevelRequests: [],
    userProgress: [],
    userMicroprogress: [],
    programMember: {},
    programMemberCompetencies: [],
    programMemberCompetencyProgress: [],
    programMemberOpportunities: [],
    programMemberLevelRequests: [],
    staffRoles: ["professor", "gsi"],
    userRequiredCompetencyAssessments: [],
  }),
  getters: {
    ...genericMixin.getters,
    userDataLoaded: (state) => {
      return !isEmpty(state.userData);
    },
    username: (state) => {
      return state.userData.username || "";
    },
    fullName: (state) => {
      let firstName = state.userData.first_name || "";
      let lastName = state.userData.last_name || "";
      return `${firstName} ${lastName}`;
    },
    userInitials: (state) => {
      let firstInitial = state.userData.first_name
        ? state.userData.first_name[0]
        : "";
      let lastInitial = state.userData.last_name
        ? state.userData.last_name[0]
        : "";
      return `${firstInitial}${lastInitial}`;
    },
    isAdmin: (state) => {
      return state.userData.admin;
    },
    isProgramAdmin: (state) => {
      return state.userData.admin || state.programUserData.role == "professor";
    },
    isProgramAssistant: (state) => {
      return state.programUserData.role == "gsi";
    },
    isProgramStaff: (state, getters) => {
      return getters.isProgramAdmin || getters.isProgramAssistant;
    },
    engagementPhase: (state) => {
      return state.programUserData.engagement_phase;
    },
    userActiveCompetencyIds: (state) => {
      let userCompetencies = state.userCompetencies || [];
      return userCompetencies
        .filter((userCompetency) => userCompetency.is_active)
        .map((userCompetency) => userCompetency.learning_objective_id);
    },
    userActiveCompetencies: (state, getters, rootState, rootGetters) => {
      const baseCompetencies = rootGetters["competency/baseCompetencies"] || [];
      return getters.userActiveCompetencyIds.map((competencyId) => {
        return (
          baseCompetencies.find(
            (competency) => competencyId == competency.id,
          ) || {}
        );
      });
    },
    userCompletedActivities: (state) => {
      return state.userActivities.filter((activity) => activity.completed);
    },
    userIncompleteActivities: (state) => {
      return state.userActivities.filter((activity) => !activity.completed);
    },
    userInProgressActivities: (state) => {
      let now = new Date();
      return state.userActivities.filter(
        (userActivity) =>
          new Date(userActivity.start_date) < now &&
          now < new Date(userActivity.end_date),
      );
    },
    userUpcomingActivities: (state) => {
      let now = new Date();
      return state.userActivities.filter(
        (userActivity) =>
          userActivity.start_date === null ||
          new Date(userActivity.start_date) > now,
      );
    },
    userUnreflectedActivities: (state) => {
      return state.userActivities.filter(
        (activity) =>
          !activity.submission_id || !activity.submission.submitted_at,
      );
    },
    userReflectedActivities: (state) => {
      return state.userActivities.filter(
        (activity) =>
          activity.submission_id && activity.submission.submitted_at,
      );
    },
    userIncompleteActivitiesForCompetency:
      (state, getters) => (competencyId) => {
        return getters.userIncompleteActivities.filter((activity) =>
          activity.assignment.learning_objective_ids.includes(competencyId),
        );
      },
    userUnreflectedActivitiesForCompetency: (state) => (competencyId) => {
      return state.userActivities.filter(
        (activity) =>
          !activity.submission_id &&
          activity.assignment.learning_objective_ids.includes(competencyId),
      );
    },
    userActivitiesForCompetency: (state) => (competencyId) => {
      return state.userActivities.filter((activity) =>
        activity.assignment.learning_objective_ids.includes(competencyId),
      );
    },
    selfScheduledActivities: (state) => {
      return state.userActivities.filter((activity) => {
        return activity.start_date !== null;
      });
    },
    programScheduledActivities: (state) => {
      return state.userActivities.filter((activity) => {
        return activity.activity_date !== undefined;
      });
    },
    scheduledActivities: (state, getters) => {
      let userActivities = getters.selfScheduledActivities.concat(
        getters.programScheduledActivities,
      );
      return userActivities.sort((a, b) => {
        let aDate = a.activity_date ? a.activity_date.start_date : a.start_date;
        let bDate = b.activity_date ? b.activity_date.start_date : b.start_date;
        return new Date(bDate) < new Date(aDate) ? 1 : -1;
      });
    },
    userPastActivites: (state) => {
      let now = new Date();
      return state.userActivities.filter((activity) => {
        return (
          !activity.completed &&
          activity.end_date &&
          Date.parse(activity.end_date) < now
        );
      });
    },
    unscheduledActivities: (state) => {
      let userActivities = state.userActivities.filter((activity) => {
        return !activity.start_date && !activity.activity_date;
      });
      return userActivities.sort((a, b) => {
        return b.assignment.name
          .toLowerCase()
          .localeCompare(a.assignment.name.toLowerCase());
      });
    },
    getReflectionById: (state) => (reflectionId) => {
      return state.userReflections.find(
        (reflection) => reflection.id === reflectionId,
      );
    },
    getLatestUserLevelRequestByDimensionId: (state) => (dimensionId) => {
      let requestsForDimension = state.userLevelRequests.filter(
        (request) => request.to_level.objective_id == dimensionId,
      );
      return (
        requestsForDimension.sort((a, b) =>
          new Date(a.updated_at) < new Date(b.updated_at) ? 1 : -1,
        )[0] || null
      );
    },
    nonApprovedUserLevelRequests: (state) => {
      return state.userLevelRequests.filter(
        (request) => request.status !== LEVEL_REQUEST_STATUS.approved,
      );
    },
    userLevelRequestsForDimension: (state) => (dimensionId) => {
      return state.userLevelRequests.filter(
        (request) => request.to_level.objective_id === dimensionId,
      );
    },
    userReflectionsForDimension: (state) => (dimensionId) => {
      return state.userReflections.filter((reflection) =>
        reflection.user_learning_objective_engagements.some(
          (engagement) => engagement.learning_objective_id === dimensionId,
        ),
      );
    },
    getLevelForDimension: (state) => (dimensionId) => {
      let approvedRequestsForDimension = state.userLevelRequests.filter(
        (request) =>
          request.status === LEVEL_REQUEST_STATUS.approved &&
          request.to_level.objective_id == dimensionId,
      );
      let highestApprovedRequest =
        approvedRequestsForDimension.sort((a, b) =>
          a.to_level.order < b.to_level.order ? 1 : -1,
        )[0] || null;
      return highestApprovedRequest ? highestApprovedRequest.to_level : null;
    },
    levelForDimension: (state) => (dimensionId) => {
      let dimensions = state.programMemberCompetencyProgress
        .map((competency) => competency.dimensions)
        .flat();
      let dimension = dimensions.find((d) => d.id === dimensionId);
      return dimensions.length
        ? dimension.current_level
        : { name: NOT_STARTED_YET.default };
    },
    userProgressFocusedDimensions: (state) => {
      return state.userProgress
        .filter((competency) => competency.is_focused)
        .flatMap((competency) => competency.dimensions);
    },
    programUserLevelUpStatistics: (state) => {
      return state.programUserData.level_requests_breakdown || {};
    },
    programUserAdminLevelUpStatistics: (state) => {
      return state.programUserData.program_level_requests_breakdown || {};
    },
    programUserPendingLevelUps: (state) => {
      return state.programMemberLevelRequests.filter(
        (request) =>
          request.status == LEVEL_REQUEST_STATUS.pending ||
          request.status == LEVEL_REQUEST_STATUS.changesRequested,
      );
    },
    programUserApprovedLevelUps: (state) => {
      return state.programMemberLevelRequests.filter(
        (request) => request.status == LEVEL_REQUEST_STATUS.approved,
      );
    },
    initialFocusedCompetencyFormState: (state, getters) => {
      return {
        selectedCompetencyIds: getters.userActiveCompetencyIds,
      };
    },
  },
  mutations: {
    setUserData(state, { userData }) {
      if (userData.user) {
        state.programUserData = userData;
        state.userData = userData.user;
      } else {
        state.userData = userData;
      }
    },
    setUserExercises(state, { data }) {
      state.userExercises = data;
    },
    setUserCompetencies(state, { data }) {
      state.userCompetencies = data;
    },
    setUserOpportunities(state, { data }) {
      state.userActivities = data;
      state.userOpportunities = data.map((activity) => activity.assignment);
    },
    setUserReflections(state, { data }) {
      state.userReflections = data;
    },
    setUserMembershipCohorts(state, { data }) {
      state.userMembershipCohorts = data;
    },
    setUserLeadershipCohorts(state, { data }) {
      state.userLeadershipCohorts = data;
    },
    setUserLevelRequests(state, { data }) {
      state.userLevelRequests = data;
    },
    setUserProgress(state, { data }) {
      state.userProgress = data;
    },
    setUserMicroprogress(state, { data }) {
      state.userMicroprogress = data;
    },
    updateUserActivity(state, data) {
      let index = state.userActivities.findIndex(
        (userActivity) => userActivity.id === data.id,
      );
      if (index >= 0) {
        state.userActivities.splice(index, 1, data);
      }
    },
    setProgramMember(state, data) {
      state.programMember = data;
    },
    setProgramMemberCompetencies(state, data) {
      state.programMemberCompetencies = data;
    },
    setProgramMemberCompetencyProgress(state, data) {
      state.programMemberCompetencyProgress = data;
    },
    setProgramMemberOpportunities(state, data) {
      state.programMemberOpportunities = data;
    },
    setProgramMemberLevelRequests(state, data) {
      state.programMemberLevelRequests = data;
    },
    setUserRequiredCompetencyAssessments(state, { data }) {
      state.userRequiredCompetencyAssessments = data;
    },
  },
  actions: {
    ...genericMixin.actions,
    async loadUserData({ getters, commit, dispatch }) {
      // For super admin pages, programId is a string (for some reason) (e.g., 'overview')
      // So we need to check not just whether it's null or not, but also if it's a number or not
      const programId = getters.programId || null;
      const url = isNaN(programId)
        ? userDataUrl.stringify()
        : programUserDataUrl.stringify({ programId: programId });
      axios.get(url).then((response) => {
        commit("setUserData", { userData: response.data });
      });
      const parsedId = parseInt(programId);
      if (!Number.isNaN(parsedId)) {
        dispatch("navigation/loadNavigationCounts", {}, { root: true });
      }
    },
    // User Exercise Actions //
    loadUserExerciseList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setUserExercises",
        urls: userRankingUrls,
      });
    },
    async saveUserExercise({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: userRankingUrls,
      });
    },
    fetchUserExercise({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: userRankingUrls,
      });
    },
    // User Competency Actions //
    async loadUserCompetencyList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setUserCompetencies",
        urls: userCompetencyUrls,
      });
    },
    saveUserCompetency({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: userCompetencyUrls,
      });
    },
    fetchUserCompetency({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: userCompetencyUrls,
      });
    },
    // User Opportunity Actions //
    async loadUserOpportunityList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setUserOpportunities",
        urls: userOpportunityUrls,
      });
    },
    saveUserOpportunity({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: userOpportunityUrls,
      });
    },
    fetchUserOpportunity({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: userOpportunityUrls,
      });
    },
    deleteUserOpportunity({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: userOpportunityUrls,
      });
    },
    // User Activity Actions //
    saveUserActivity({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: userActivityUrls,
      });
    },
    fetchUserActivity({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: userActivityUrls,
      });
    },
    deleteUserActivity({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: userActivityUrls,
      });
    },
    // User Reflection Actions //
    async loadUserReflectionList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setUserReflections",
        urls: userReflectionUrls,
      });
    },
    async createUserReflection({ getters }, { userActivityId }) {
      let url = userActivityUrls.createReflection.stringify({
        programId: getters.programId,
        objectId: userActivityId,
      });
      try {
        const response = await axios.post(url);
        return response;
      } catch (err) {
        return err.response;
      }
    },
    saveUserReflection({ getters }, { data, activityId }) {
      let urls = userReflectionUrls;
      let apiUrlPattern = data.id ? urls.update : urls.create;
      let url = apiUrlPattern.stringify(getters.urlParams({ id: activityId }));
      return (data.id ? axios.put : axios.post)(url, data).then(
        (response) => response.data,
      );

      //return dispatch('saveInstanceData', { data: data, urls: userReflectionUrls });
    },
    fetchUserReflection({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: userReflectionUrls,
      });
    },
    deleteUserReflection({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: userReflectionUrls,
      });
    },
    saveReflectionAttachments(_, { programId, objectId, files }) {
      let url = userReflectionAttachFilesUrl.stringify({
        programId,
        objectId,
      });
      let formData = new FormData();
      files.forEach((file) => {
        formData.append("files[]", file);
      });
      return axios.post(url, formData).then((response) => response.data);
    },
    removeReflectionAttachment(_, { programId, objectId, fileId }) {
      let url = userReflectionDetachFileUrl.stringify({
        programId,
        objectId,
      });
      let formData = new FormData();
      formData.append("fileId", fileId);
      return axios.post(url, formData).then((response) => response.data);
    },
    loadUserMembershipCohortList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setUserMembershipCohorts",
        urls: { list: userMembershipCohortsListUrl },
      });
    },
    loadUserLeadershipCohortList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setUserLeadershipCohorts",
        urls: { list: userLeadershipCohortsListUrl },
      });
    },
    async markOnboardingCompleted({ getters }) {
      const url = programOnboardingCompletedUrl.stringify(
        getters.urlParams({}),
      );
      await axios.post(url);
    },
    // User Level Request Actions //
    async loadUserLevelRequestList({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setUserLevelRequests",
        urls: userLevelRequestUrls,
      });
    },
    fetchUserLevelRequestData({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: userLevelRequestUrls,
      });
    },
    saveUserLevelRequest({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: userLevelRequestUrls,
      });
    },
    // User Progress Actions //
    loadUserProgress({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setUserProgress",
        urls: { list: userProgressListUrl },
      });
    },
    loadUserMicroprogress({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setUserMicroprogress",
        urls: { list: userMicroprogressListUrl },
      });
    },
    // User Activity Actions //
    loadProgramUser({ commit }, data) {
      let url = programMemberUrls.details.stringify({
        programId: data.programId,
        objectId: data.objectId,
      });
      return axios.get(url).then((response) => {
        commit("setProgramMember", response.data);
      });
    },
    loadProgramUserCompetencies({ commit }, data) {
      let url = programMemberUrls.competency.list.stringify({
        programId: data.programId,
        objectId: data.objectId,
      });
      return axios.get(url).then((response) => {
        commit("setProgramMemberCompetencies", response.data);
      });
    },
    loadProgramUserAdminCompetencyProgress({ commit }, data) {
      let url = programMemberUrls.competency.adminProgress.stringify({
        programId: data.programId,
        objectId: data.objectId,
      });
      return axios.get(url).then((response) => {
        commit("setProgramMemberCompetencyProgress", response.data);
      });
    },
    async loadProgramUserStudentCompetencyProgress({ commit }, data) {
      let url = programMemberUrls.competency.studentProgress.stringify({
        programId: data.programId,
        objectId: data.objectId,
      });
      const response = await axios.get(url);
      commit("setProgramMemberCompetencyProgress", response.data);
      return response.data;
    },
    updateProgramUserCompetencyLevel(context, data) {
      let url = programMemberUrls.competency.update.stringify({
        programId: data.programId,
        objectId: data.objectId,
      });
      return axios.post(url, data).then((response) => response.data);
    },
    deleteProgramUserCompetencyLevel(context, data) {
      let url = programMemberUrls.competency.delete.stringify({
        programId: data.programId,
        objectId: data.objectId,
      });
      return axios.post(url, data).then((response) => response.data);
    },
    loadProgramUserOpportunities({ commit }, data) {
      let url = programMemberUrls.opportunity.list.stringify({
        programId: data.programId,
        objectId: data.objectId,
      });
      return axios.get(url).then((response) => {
        commit("setProgramMemberOpportunities", response.data);
      });
    },
    loadProgramUserLevelRequests({ commit }, data) {
      let url = programMemberUrls.levelRequest.list.stringify({
        programId: data.programId,
        objectId: data.objectId,
      });
      return axios.get(url).then((response) => {
        commit("setProgramMemberLevelRequests", response.data);
      });
    },
    loadUserRequiredCompetencyAssessments({ dispatch }) {
      return dispatch("loadObjectList", {
        mutation: "setUserRequiredCompetencyAssessments",
        urls: { list: userRequiredCompetencyAssessmentsUrl },
      });
    },
  },
};
