<template>
  <ul class="form-field-errors_spire">
    <li
      v-for="(error, index) in errors"
      :key="`${keyPrefix}-${index}`"
      role="status"
      aria-live="polite"
      class="form-field-error_spire"
    >
      {{ error }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "SpireErrorList",
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    keyPrefix: {
      type: String,
      required: true,
    },
  },
};
</script>
