<template>
  <div class="menububble__wrapper">
    <button
      type="button"
      @click="openModal"
      class="menububble__button"
      :class="{ 'is-active': isActive }"
    >
      <slot name="button-content"></slot>
    </button>

    <spire-modal
      v-if="modalIsOpen"
      @close-modal="closeModal"
      :open="modalIsOpen"
      :footer="false"
    >
      <template #header>
        <slot name="block-modal-header"></slot>
      </template>
      <template #content>
        <slot name="block-modal-form"></slot>
      </template>
      <template #footer>
        <slot name="footer"></slot>
      </template>
    </spire-modal>
  </div>
</template>

<script setup>
import { ref } from "vue";
defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});
const modalIsOpen = ref(false);

const openModal = () => {
  modalIsOpen.value = true;
};
const closeModal = () => {
  modalIsOpen.value = false;
};

defineExpose({ closeModal });
</script>
