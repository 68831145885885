<template>
  <teleport to="main">
    <div class="modal-outer-wrapper_spire" v-focus-trap v-bind="$attrs">
      <div class="modal-wrapper_spire" @keyup.esc="$emit('close-modal')">
        <div class="modal-container_spire">
          <div class="modal-header_spire">
            <p class="h3">
              <slot name="header"></slot>
            </p>
            <spire-button
              v-if="hasCloseButton"
              variant="tertiary"
              aria-label="Close"
              @click.once="$emit('close-modal')"
              class="modal-header__close-btn no-underline"
              icon-left="fa-times"
            />
          </div>
          <div class="modal-content_spire">
            <slot name="content"></slot>
          </div>
          <div class="modal-footer_spire" v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
        <div
          class="overlay_spire"
          v-if="overlay"
          @click="$emit('close-modal')"
        />
      </div>
    </div>
  </teleport>
</template>
<script>
export default {
  name: "SpireModal",
  props: {
    overlay: {
      type: Boolean,
      default: true,
    },
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close-modal"],
  methods: {
    getFreezeElement() {
      return document.querySelector("[data-target='spire-modal-freeze']");
    },
    toggleNoScroll(force = undefined) {
      const freezeEl = this.getFreezeElement();
      freezeEl.classList.toggle("freeze", force);
    },
  },
  mounted() {
    this.toggleNoScroll(this.open);
  },
  unmounted() {
    this.toggleNoScroll(false);
  },
};
</script>
