<template>
  <span class="spire-tag spire-tag--min-content" :class="savingClass">
    {{ savingStatus }}
  </span>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  isSaving: Boolean,
});

const savingStatus = computed(() => {
  return props.isSaving ? "Saving..." : "Saved";
});

const savingClass = computed(() => {
  return props.isSaving ? "spire-tag--info wait-loop" : "spire-tag--success";
});
</script>
