<template>
  <block-modal ref="blockModalRef" :is-active="isActive">
    <template #button-content> Video </template>
    <template #block-modal-header> Add a video </template>
    <template #block-modal-form>
      <form-kit type="form" id="video" :actions="false" @submit="handleSubmit">
        <h3>Embed URL</h3>
        <form-kit
          label="URL"
          name="src"
          type="url"
          :help="helpText"
          validation="required|isYouTubeUrl|isEmbedUrl"
          :validation-messages="{
            isYouTubeUrl: 'Make sure you are entering a YouTube URL.',
            isEmbedUrl: `Make sure you are entering an embed URL (e.g., 'https://www.youtube.com/embed/Ygt0m2_sHgE').`,
          }"
        />
      </form-kit>
    </template>
    <template #footer>
      <spire-button
        type="button"
        @click="submitForm('video')"
        variant="primary"
        title="Add Video"
      />
    </template>
  </block-modal>
</template>

<script setup>
import { ref } from "vue";
import { submitForm } from "@formkit/vue";
import BlockModal from "./BlockModal.vue";

defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["add-video"]);
const blockModalRef = ref();
const handleSubmit = ({ src }) => {
  emit("add-video", {
    src,
    modal: blockModalRef.value,
  });
};

const helpText = `To locate the embed URL for your video, 
        click the "Share" button on the video's YouTube page, 
        select "Embed", and copy ONLY the src attribute from the HTML 
        (e.g., "https://www.youtube.com/embed/Ygt0m2_sHgE".`;
</script>
