import { apiClient } from "@/programs/services/ApiClient.js";
import {
  programScheduledAnnouncementsListUrl,
  programScheduledAnnouncementsDetailUrl,
} from "@/programs/urls.js";

const deserializeScheduledAnnouncement = ({
  id,
  title,
  start_date,
  end_date,
  cadence,
  context_type,
  tailored_content_reference_id,
  cohort_id,
  recipient_group,
}) => ({
  id,
  title,
  cadence,
  startDate: start_date,
  endDate: end_date,
  contextType: context_type,
  tailoredContentReferenceId: tailored_content_reference_id,
  cohortId: cohort_id,
  recipientGroup: recipient_group,
});

const deserializeErrors = ({
  base,
  id,
  title,
  start_date,
  end_date,
  cadence,
  context_type,
  cohort_id,
  tailored_content_reference_id,
  recipient_group,
}) => ({
  base,
  id,
  title,
  cadence,
  startDate: start_date,
  endDate: end_date,
  contextType: context_type,
  tailoredContentReferenceId: tailored_content_reference_id,
  cohortId: cohort_id,
  recipientGroup: recipient_group,
});
const serializeScheduledAnnouncement = ({
  id,
  title,
  startDate,
  endDate,
  cadence,
  contextType,
  cohortId,
  tailoredContentReferenceId,
  recipientGroup,
}) => ({
  id,
  title,
  cadence,
  context_type: contextType,
  start_date: startDate,
  end_date: endDate,
  cohort_id: cohortId,
  tailored_content_reference_id: tailoredContentReferenceId,
  recipient_group: recipientGroup,
});

export const getScheduledAnnouncements = async (programId) => {
  const url = programScheduledAnnouncementsListUrl.stringify({ programId });
  const { data } = await apiClient.get(url);
  return data.map(deserializeScheduledAnnouncement);
};

export const createScheduledAnnouncement = async (
  programId,
  scheduledAnnouncement,
) => {
  const url = programScheduledAnnouncementsListUrl.stringify({ programId });
  const serializedAnnouncement = serializeScheduledAnnouncement(
    scheduledAnnouncement,
  );
  const { data } = await apiClient.post(url, serializedAnnouncement);
  const { errors, instance, status } = data;
  return {
    status,
    instance: deserializeScheduledAnnouncement(instance),
    errors: errors && deserializeErrors(errors),
  };
};

export const patchScheduledAnnouncement = async (
  programId,
  scheduledAnnouncement,
) => {
  const url = programScheduledAnnouncementsDetailUrl.stringify({
    programId,
    objectId: scheduledAnnouncement.id,
  });
  const serializedAnnouncement = serializeScheduledAnnouncement(
    scheduledAnnouncement,
  );
  const { data } = await apiClient.patch(url, serializedAnnouncement);
  const { errors, instance, status } = data;
  return {
    status,
    instance: deserializeScheduledAnnouncement(instance),
    errors: errors && deserializeErrors(errors),
  };
};

export const deleteScheduledAnnouncement = async (
  programId,
  scheduledAnnouncementId,
) => {
  const url = programScheduledAnnouncementsDetailUrl.stringify({
    programId,
    objectId: scheduledAnnouncementId,
  });
  const response = await apiClient.delete(url);
  return response;
};
