import buildURL from "axios/lib/helpers/buildURL";

const createRouteHelpers = ({ routes }) => {
  const getRoute = (name) => {
    const route = routes.find((route) => route.name === name);
    if (route === undefined) {
      throw new Error(`${name} has no matching route definition.`);
    }
    return route;
  };
  const to = (name, params, query = {}) => {
    const route = getRoute(name);
    const url = route.pattern.stringify(params);
    return buildURL(url, query);
  };
  const goTo = (name, params, query = {}) => {
    window.location.assign(to(name, params, query));
  };

  return { to, goTo };
};

function install(Vue, options) {
  Vue.config.globalProperties.$path = createRouteHelpers(options);
}

export default {
  install,
};
