<template>
  <div class="spire-accordion">
    <component
      :is="triggerHeaderElement"
      class="spire-accordion__header m-0 p-0"
    >
      <button
        @click.prevent="toggleExpanded"
        :aria-expanded="ariaExpanded"
        class="spire-accordion__trigger m-0"
        :class="allTriggerClasses"
        :aria-controls="contentId"
        :id="triggerId"
      >
        <span class="spire-accordion__trigger__text">
          <slot name="trigger_text"></slot>
        </span>
        <spire-tag
          v-if="counter > 0"
          class="mx-1"
          :tag-number="counter"
          :title="unitCounter"
          variant="default"
        />

        <fa-icon class="spire-accordion__status-indicator" icon="fa-caret-down">
        </fa-icon>
      </button>
    </component>
    <div
      hidden
      :id="contentId"
      role="region"
      :aria-labelledby="triggerId"
      class="spire-accordion__content"
      :class="allContentClasses"
      ref="content"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { pluralize } from "@/courses/utilities/base.js";

const CLOSED = "closed";
const EXPANDED = "expanded";
const states = {
  closed: CLOSED,
  expanded: EXPANDED,
};

export default {
  name: "SpireAccordion",
  props: {
    triggerHeaderElement: {
      type: String,
      default: "h3",
    },
    triggerId: {
      type: String,
      required: true,
    },
    contentId: {
      type: String,
      required: true,
    },
    triggerClasses: {
      type: String,
      default: "",
    },
    contentClasses: {
      type: String,
      default: "",
    },
    defaultState: {
      type: String,
      default: states.closed,
    },
    counter: {
      type: Number,
      default: 0,
    },
    unitType: {
      type: String,
      default: "item",
    },
  },
  data() {
    return {
      expanded: false,
      allContentClasses: "",
    };
  },
  computed: {
    ariaExpanded() {
      return this.expanded ? "true" : "false";
    },
    allTriggerClasses() {
      return this.expanded
        ? `${this.triggerClasses} expanded`
        : this.triggerClasses;
    },
    unitCounter() {
      return `${pluralize(this.unitType, this.counter)}`;
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    hideAfterCollapsing() {
      this.$refs.content.hidden = true;
    },
  },
  watch: {
    expanded(showContent) {
      this.$nextTick(() => {
        if (showContent && this.$refs.content) {
          this.$refs.content.hidden = false;
        } else {
          setTimeout(this.hideAfterCollapsing, 400);
        }
        setTimeout(() => {
          this.allContentClasses = showContent
            ? `${this.contentClasses} expanded`
            : this.contentClasses;
        }, 10);
      });
    },
  },
  created() {
    this.expanded = this.defaultState === states.expanded;
  },
};
</script>
