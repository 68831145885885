import axios from "axios";

const csrftoken = document.head.querySelector("[name='csrf-token']").attributes
  .content.value;

export const apiClient = axios.create({
  headers: {
    common: {
      "X-CSRF-Token": csrftoken,
    },
  },
});
