import axios from "axios";

import { programCohortUrls, programCohortMemberListUrl } from "../urls.js";

import { genericMixin } from "./generic.js";

const initialState = {
  cohort: [],
  cohortMembers: [],
};

export const cohortModule = {
  namespaced: true,
  state: () => ({
    ...initialState,
  }),
  getters: {
    ...genericMixin.getters,
    members(state) {
      return state.cohortMembers.data;
    },
    membersPagination(state) {
      return state.cohortMembers.metadata || {};
    },
  },
  mutations: {
    ...genericMixin.mutations,
    setCohort(state, data) {
      state.cohort = data;
    },
    setCohortMembers(state, { data }) {
      state.cohortMembers = data;
    },
  },
  actions: {
    // Generic Actions //
    ...genericMixin.actions,
    // Cohort Actions //
    loadCohortMemberList({ getters, commit }, { cohortId, params }) {
      let url = programCohortMemberListUrl.stringify({
        programId: getters.programId,
        objectId: cohortId,
      });
      return axios.get(url, { params }).then((response) => {
        commit("setCohortMembers", { data: response.data });
      });
    },
    async fetchCohortDetails({ dispatch, commit }, data) {
      let cohort = await dispatch("fetchInstanceDetailsData", {
        data: data,
        urls: programCohortUrls,
      });
      commit("setCohort", cohort);
    },
    fetchCohortData({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: programCohortUrls,
      });
    },
    saveCohort({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: programCohortUrls,
      });
    },
    deleteCohort({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: programCohortUrls,
      });
    },
  },
};
