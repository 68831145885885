import axios from "axios";

export const genericMixin = {
  getters: {
    programId: (state, getters, rootState) => {
      return rootState.programId;
    },
    urlParams: (state, getters) => (instance) => {
      return {
        ...instance,
        programId: getters.programId,
        objectId: instance.id,
      };
    },
  },
  mutations: {},
  actions: {
    // Generic Actions //
    loadObjectList({ getters, commit }, { mutation, urls, params = {} }) {
      const url = urls.list.stringify(getters.urlParams({}));
      return axios.get(url, { params }).then((response) => {
        commit(mutation, { data: response.data });
      });
    },
    fetchNewInstanceData({ getters }, { createUrl }) {
      let data = { programId: getters.programId };
      let url = createUrl.stringify(data);
      return axios.get(url).then((response) => response.data);
    },
    fetchInstanceData({ getters }, { data, urls }) {
      let apiUrlPattern = data.id ? urls.update : urls.create;
      let url = apiUrlPattern.stringify(getters.urlParams(data));
      return axios.get(url).then((response) => response.data);
    },
    saveInstanceData({ getters }, { data, urls }) {
      let apiUrlPattern = data.id ? urls.update : urls.create;
      let url = apiUrlPattern.stringify(getters.urlParams(data));
      return (data.id ? axios.put : axios.post)(url, data).then(
        (response) => response.data,
      );
    },
    deleteInstanceData({ getters }, { data, urls }) {
      let url = urls.update.stringify(getters.urlParams(data));
      return axios.delete(url, data).then((response) => response.data);
    },
    fetchInstanceDetailsData({ getters }, { data, urls }) {
      const url = urls.details.stringify(getters.urlParams(data));
      return axios.get(url).then((response) => response.data);
    },
    // End Generic Actions //
  },
};
