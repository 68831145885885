import { Plugin } from "vue";

import SpireAutosaveIndicator from "./components/SpireAutosaveIndicator.vue";
import SpireActionMenu from "./components/SpireActionMenu.vue";
import SpireActionMenuItem from "./components/SpireActionMenuItem.vue";
import SpireBreadcrumbs from "./components/SpireBreadcrumbs.vue";
import SpireButton from "./components/SpireButton.vue";
import SpireButtonGroup from "./components/SpireButtonGroup.vue";
import SpireCard from "./components/SpireCard.vue";
import SpireDateRangeFilter from "./components/SpireDateRangeFilter.vue";
import SpireErrorList from "./components/SpireErrorList.vue";
import SpireIframe from "./components/SpireIframe.vue";
import SpireTag from "./components/SpireTag.vue";
import SpireTable from "./components/SpireTable.vue";
import SpireTd from "./components/SpireTd.vue";
import SpireTh from "./components/SpireTh.vue";
import SpireTooltip from "./components/SpireTooltip.vue";
import SpireTr from "./components/SpireTr.vue";
import SpirePaginationButtons from "./components/SpirePaginationButtons.vue";
import SpireModal from "./components/SpireModal.vue";
import SpireModalAndTrigger from "./components/SpireModalAndTrigger.vue";
import SpireEmptyState from "./components/SpireEmptyState.vue";
import SpireError from "./components/SpireError.vue";

declare module "vue" {
  interface GlobalComponents {
    SpireAutosaveIndicator: typeof SpireAutosaveIndicator;
    SpireActionMenu: typeof SpireActionMenu;
    SpireActionMenuItem: typeof SpireActionMenuItem;
    SpireBreadcrumbs: typeof SpireBreadcrumbs;
    SpireButton: typeof SpireButton;
    SpireButtonGroup: typeof SpireButtonGroup;
    SpireCard: typeof SpireCard;
    SpireDateRangeFilter: typeof SpireDateRangeFilter;
    SpireErrorList: typeof SpireErrorList;
    SpireIframe: typeof SpireIframe;
    SpireTag: typeof SpireTag;
    SpireTable: typeof SpireTable;
    SpireTd: typeof SpireTd;
    SpireTh: typeof SpireTh;
    SpireTooltip: typeof SpireTooltip;
    SpireTr: typeof SpireTr;
    SpirePaginationButtons: typeof SpirePaginationButtons;
    SpireModal: typeof SpireModal;
    SpireModalAndTrigger: typeof SpireModalAndTrigger;
    SpireEmptyState: typeof SpireEmptyState;
    SpireError: typeof SpireError;
  }
}

const CoreComponentPlugin: Plugin = {
  install(app) {
    app.component("SpireAutosaveIndicator", SpireAutosaveIndicator);
    app.component("SpireActionMenu", SpireActionMenu);
    app.component("SpireActionMenuItem", SpireActionMenuItem);
    app.component("SpireBreadcrumbs", SpireBreadcrumbs);
    app.component("SpireButton", SpireButton);
    app.component("SpireButtonGroup", SpireButtonGroup);
    app.component("SpireCard", SpireCard);
    app.component("SpireDateRangeFilter", SpireDateRangeFilter);
    app.component("SpireErrorList", SpireErrorList);
    app.component("SpireIframe", SpireIframe);
    app.component("SpireTag", SpireTag);
    app.component("SpireTable", SpireTable);
    app.component("SpireTd", SpireTd);
    app.component("SpireTh", SpireTh);
    app.component("SpireTooltip", SpireTooltip);
    app.component("SpireTr", SpireTr);
    app.component("SpirePaginationButtons", SpirePaginationButtons);
    app.component("SpireModal", SpireModal);
    app.component("SpireModalAndTrigger", SpireModalAndTrigger);
    app.component("SpireEmptyState", SpireEmptyState);
    app.component("SpireError", SpireError);
  },
};

export default CoreComponentPlugin;
