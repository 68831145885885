<script setup>
import { computed, ref, onMounted } from "vue";
import VueMultiselect from "vue-multiselect";
import { getCompetencyOptions } from "@/programs/services/LearningObjectiveService";
import { useVMSFormKitShim } from "@/programs/composables/useVMSFormKitShim.js";

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const multiple = props.context.multiple;
const closeOnSelect = props.context.closeOnSelect || multiple === false;
const searchable = props.context.searchable;
const allowEmpty = props.context.allowEmpty;

const options = ref([]);
const loading = ref(false);
const error = ref(false);

const placeholder = computed(() => {
  if (error.value === true) {
    return "Failed to load competencies";
  }
  return props.context.placeholder || "Type to search competencies";
});

const { value } = useVMSFormKitShim(props.context, options);

onMounted(async () => {
  error.value = false;
  loading.value = true;
  try {
    const response = await getCompetencyOptions({
      programId: props.context.programId,
    });
    options.value = response.data;
  } catch (err) {
    error.value = true;
    throw err;
  } finally {
    loading.value = false;
  }
});
</script>

<template>
  <vue-multiselect
    v-model="value"
    :show-labels="true"
    :multiple="multiple"
    :searchable="searchable"
    :close-on-select="closeOnSelect"
    :options="options"
    :disabled="context.disabled"
    :loading="loading"
    :name="context.node.name"
    :id="context.id"
    :placeholder="placeholder"
    :allow-empty="allowEmpty"
    label="label"
    track-by="value"
    open-direction="auto"
    v-bind="$attrs"
    class="vue-multiselect"
  />
</template>
