import axios from "axios";
import { tailoringUrls, institutionalDataUrls } from "../urls.js";

import { genericMixin } from "./generic.js";

const initialState = {
  tailoringReferences: [],
  tailoringProjects: [],
  tailoringCoaches: [],
  tailoringDocuments: [],
  tailoringMessages: [],
  tailoringSurveys: [],
  institutionTerms: [],
  institutionCourses: [],
  institutionAssignments: [],
  selectedTerm: null,
  selectedCourse: null,
};

export const tailoringModule = {
  namespaced: true,
  state: () => ({
    ...initialState,
  }),
  getters: {
    ...genericMixin.getters,
    surveyReferences: (state) => {
      return state.tailoringReferences.filter(
        (ref) => ref.content_type === "survey",
      );
    },
    emailReferences: (state) => {
      return state.tailoringReferences.filter(
        (ref) => ref.content_type === "email",
      );
    },
  },
  mutations: {
    setTailoringReferences(state, { data }) {
      state.tailoringReferences = data;
    },
    setTailoringProjects(state, { data }) {
      state.tailoringProjects = data;
    },
    setTailoringCoaches(state, { data }) {
      state.tailoringCoaches = data;
    },
    setTailoringDocuments(state, { data }) {
      state.tailoringDocuments = data;
    },
    setTailoringMessages(state, { data }) {
      state.tailoringMessages = data;
    },
    setTailoringSurveys(state, { data }) {
      state.tailoringSurveys = data;
    },
    // Institution Data Mutations //
    setInstitutionTerms(state, { data }) {
      state.institutionTerms = data;
    },
    setInstitutionCourses(state, { data }) {
      state.institutionCourses = data;
    },
    setInstitutionAssignments(state, { data }) {
      state.institutionAssignments = data;
    },
    setSelectedTerm(state, { data }) {
      state.selectedTerm = data;
    },
    setSelectedCourse(state, { data }) {
      state.selectedCourse = data;
    },
  },
  actions: {
    ...genericMixin.actions,
    // Tailoring Actions //
    loadTailoringProjectsList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setTailoringProjects",
        urls: tailoringUrls.project,
      });
    },
    loadTailoringCoachList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setTailoringCoaches",
        urls: tailoringUrls.coach,
      });
    },
    loadTailoringDocumentList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setTailoringDocuments",
        urls: tailoringUrls.document,
      });
    },
    loadTailoringMessageList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setTailoringMessages",
        urls: tailoringUrls.message,
      });
    },
    loadTailoringSurveyList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setTailoringSurveys",
        urls: tailoringUrls.survey,
      });
    },

    loadTailoringReferenceList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setTailoringReferences",
        urls: tailoringUrls.reference,
      });
    },
    fetchTailoringReferenceData({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: tailoringUrls.reference,
      });
    },
    fetchTailoringReferenceContent({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: { update: tailoringUrls.reference.content },
      });
    },
    saveTailoringReference({ dispatch }, data) {
      return dispatch("saveInstanceData", {
        data: data,
        urls: tailoringUrls.reference,
      });
    },
    deleteTailoringReference({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data: data,
        urls: tailoringUrls.reference,
      });
    },
    fetchTailoringSurveyContent({ dispatch }, data) {
      return dispatch("fetchInstanceData", {
        data: data,
        urls: { update: tailoringUrls.survey.content },
      });
    },
    fetchTailoringSurveyPage({ getters }, data) {
      let url = tailoringUrls.survey.content.stringify(getters.urlParams(data));
      url = `${url}?page_url=${data.page_url}`;
      return axios.get(url).then((response) => response.data);
    },
    submitTailoringSurveyContent({ getters }, data) {
      let url = tailoringUrls.survey.content.stringify(getters.urlParams(data));
      return axios.put(url, data).then((response) => response.data);
    },
    // End Tailoring Actions //

    // Institutional Data Actions //
    loadInstitutionTermList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setInstitutionTerms",
        urls: institutionalDataUrls.terms,
      });
    },
    loadInstitutionCourseList({ dispatch }) {
      dispatch("loadObjectList", {
        mutation: "setInstitutionCourses",
        urls: institutionalDataUrls.courses,
      });
    },

    loadInstitutionAssignmentList(
      { commit, rootState },
      { course_code, term_code },
    ) {
      const data = {
        term_code: term_code,
        course_code: course_code.split("_")[0],
      };
      const assignmentListUrl =
        institutionalDataUrls.canvasAssignment.list.stringify({
          programId: rootState.programId,
        });

      return axios.get(assignmentListUrl, { params: data }).then((response) => {
        commit("setInstitutionAssignments", { data: response.data });
      });
    },
  },
};
