import { DateTime } from "luxon";
import { validateUrl } from "./base.js";

export const isBefore = ({ value, getFormValues }, comparisonFieldName) => {
  const comparisonValue = getFormValues()[comparisonFieldName];
  return (
    value && comparisonValue && new Date(value) < new Date(comparisonValue)
  );
};

export const isFutureDateTime = ({ value }) =>
  DateTime.fromISO(value).diffNow() > 0;

export const isValidUrl = ({ value }) => validateUrl(value);

export const isEmbedUrl = ({ value }) => {
  const embedBase = /^https:\/\/www.youtube.com\/embed/;
  return value.match(embedBase) !== null;
};

export const isYouTubeUrl = ({ value }) => {
  const youtubeBase = /^https:\/\/www.youtube.com\//;
  return value.match(youtubeBase) !== null;
};

export const isBeforeEndDate = (node) => {
  const { start_date, end_date } = node.parent.value;
  return start_date && end_date
    ? new Date(start_date) <= new Date(end_date)
    : true;
};
