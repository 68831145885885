<script setup lang="ts">
import { computed } from "vue";
import type { AxiosError } from "axios";

const props = defineProps<{
  error?: AxiosError;
}>();

const responseStatusCode = computed(() => props.error?.response?.status);
</script>
<template>
  <div class="error-logo page-centered mt-5">
    <template v-if="responseStatusCode">
      Sorry! Server responded with status code {{ responseStatusCode }}.
    </template>
    <template v-else> Unexpected error occurred. </template>
  </div>
</template>
